import React from "react";
import firebase from "firebase";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

export default function Tile(props) {
  const auth = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();

  const onSucDeleteImage = () => {
    dispatch(actionCreators.populateGallery(props.galleryName));
    dispatch(actionCreators.populateBannerGallery(props.galleryName));
    alert("Delete was successfull");
  };

  const onOpenImg = () => {
    props.onOpenImg();
  };

  const onDeleteImage = (e) => {
    e.stopPropagation();
    const onEror = (error) => {
      console.log(error);
    };

    const folderName = props.galleryName;
    const splitPath = folderName + "%2F";

    let imgName = props.data.image;
    imgName = imgName.split(splitPath)[1];
    imgName = imgName.split("?")[0];
    const desertRef = firebase
      .storage()
      .ref()
      .child(folderName + "/" + imgName);
    desertRef.delete().then(onSucDeleteImage, onEror);
  };

  return (
    <div className="gallery-tile" onClick={() => onOpenImg()}>
      <img src={props.data.image} alt={props.data.name} />

      {auth && (
        <Button className="gallery-tile__btn" onClick={onDeleteImage}>
          X
        </Button>
      )}
    </div>
  );
}
