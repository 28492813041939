import React from "react";
import { useHistory } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";

import ConsultingSVG from "./constultingSvg";
import WebshopSVG from "./webshopSvg";
import { useSelector } from "react-redux";

export default function HomePage() {
  const localStrings = useSelector(state => state.languages.localStrings);

  const history = useHistory();
  const navigateTo = (url) => {
    history.push(url);
  };

  const slideIn = useSpring({
    from: {
      transform: "translateX(100vw)",
    },
    transform: "translateX(0)",
  });

  return (
    <div className="home-page">
      <Helmet>
        <title>ROST International</title>
        <meta name="description" content="ROST International" />
        <meta
          name="google-site-verification"
          content="LsXkLwO-HwbChhIGAw4G9ercHNY8wTKD-nMx2jceU54"
        />
      </Helmet>

      <Container className="home-page__content">
        <animated.div
          style={slideIn}
          className="home-page__background-wrapper"
        />
        <div
          className="home-page__webshop"
          onClick={() => navigateTo("/products")}
        >
          <h2 className="home-page__title home-page__title--webshop">
            {localStrings.pages.home.webshop}
          </h2>
          <div className="home-page__image-container">
            <WebshopSVG />
          </div>
        </div>
        <div
          className="home-page__consulting"
          onClick={() => navigateTo("/consulting")}
        >
          <animated.div style={slideIn} className="home-page__image-container">
            <ConsultingSVG />
          </animated.div>

          <h2 className="home-page__title home-page__title--consulting">
            {localStrings.pages.home.consulting}
          </h2>
        </div>
      </Container>
    </div>
  );
}
