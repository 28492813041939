import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import StripGallery from "./StripGallery.js";
import ProductContactForm from "./ProductContactForm.js";
import EditableText from "../../components/EditableText";
import ParticlesBg from "particles-bg";
import CommercialSlider from "../../components/CommercialSlider.js";
import electronicsPolygon from "../../../assets/images/products/polygons/laptop.png";
import rcModelsPolygon from "../../../assets/images/products/polygons/drone.png";
import sparePartsPolygon from "../../../assets/images/products/polygons/sparts.png";
import tiresPolygon from "../../../assets/images/products/polygons/tires.png";
import useWindowSize from "@rooks/use-window-size";

const polygon = (productCategory) => {
  switch (productCategory) {
    case "spareParts":
      return sparePartsPolygon;
    case "tires":
      return tiresPolygon;
    case "electronics":
      return electronicsPolygon;
    case "rcModels":
      return rcModelsPolygon;
    default:
      return null;
  }
};

const name = (productCategory, products) => {
  switch (productCategory) {
    case "spareParts":
      return products.spareParts;
    case "tires":
      return products.tires;
    case "electronics":
      return products.electronics;
    case "rcModels":
      return products.rcModels;
    default:
      return null;
  }
};

const galleryName = (productCategory) => {
  switch (productCategory) {
    case "spareParts":
      return "sparePartsGallery";
    case "tires":
      return "tiresGallery";
    case "electronics":
      return "electronicsGallery";
    case "rcModels":
      return "RCModelsGallery";
    default:
      return null;
  }
};

const editableTextAbout = (productCategory) => {
  switch (productCategory) {
    case "spareParts":
      return "shopOnlineSpareParts";
    case "tires":
      return "shopOnlineTires";
    case "electronics":
      return "shopOnlineElectronics";
    case "rcModels":
      return "shopOnlineModels";
    default:
      return null;
  }
};

const containerWidth = (screenWidth) => {
  if (screenWidth > 1200) return 1140;
  if (screenWidth > 992) return 960;
  if (screenWidth > 768) return 720;
  if (screenWidth > 576) return 540;
  return screenWidth;
};

export default function ProductCategoryPage(props) {
  const products = useSelector(
    (state) => state.languages.localStrings.pages.products
  );
  const { innerWidth } = useWindowSize();
  const width = containerWidth(Number(innerWidth));
  const scale = width / 287;

  return (
    <div className="product-category__wrapper">
      <ParticlesBg
        className="product-category__particles"
        color="#3c94ba"
        num={scale*25}
        type="cobweb"
        bg={true}
      />
      <Container>
        <img
          className="product-category__polygon"
          src={polygon(props.productCategory)}
          alt="product category polygon"
        />
        <Row className="product-category__title">
          {name(props.productCategory, products).toUpperCase()}
        </Row>
        <Row>
          <StripGallery galleryName={galleryName(props.productCategory)} />
        </Row>
        <Row>
          <Col>
            <div className="product-category__text editable-text__wrapper">
              <EditableText textKey={editableTextAbout(props.productCategory)} />
            </div>
          </Col>
        </Row>
        &nbsp; 
        <ProductContactForm prodName={name(props.productCategory, products)} />

        <Row className="mt-3 mb-5">
          <Col sm="12" md="6">
            <CommercialSlider
              galleryName={galleryName(props.productCategory) + "Banner1"}
            />
          </Col>
          <Col sm="12" md="6">
            <CommercialSlider
              galleryName={galleryName(props.productCategory) + "Banner2"}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
