import React from "react";
import GalleryModal from "../../components/gallery/GalleryModal.js";
import GalleryFunctionality from "../../components/gallery/GalleryFunctionality.js";
import { NavLink } from "react-router-dom";

export default function ReferenceGallery(props) {
  return (
    <GalleryFunctionality
      galleryName={props.galleryName}
      displayAndDeleteImages={(images, galleryName) => (
        <GalleryModal
          galleryName={galleryName}
          images={images}
          mainDisplay={(openModal) => {
            if (!images || images.length === 0) return <div></div>;
            const coverImage = images[0];
            return (
              <div className="mb-2">
                <div
                  className="reference-gallery-tile"
                  onClick={() => openModal(coverImage)}
                >
                  <img src={coverImage.image} alt={coverImage.name} />
                </div>
              </div>
            );
          }}
        />
      )}
      adminButton={
        <NavLink to={"/edit-gallery/" + props.galleryName}>
          Click here to add or delete images for the above reference
        </NavLink>
      }
    />
  );
}
