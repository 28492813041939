import React from "react";
import { connect } from "react-redux";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../axios-orders";
import * as actionCreators from "../../../store/actions/index";
import { Button, Form } from "react-bootstrap";
class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      bannerUrl: null,
      isBanner:
        this.props.galleryName.endsWith("Banner1") ||
        this.props.galleryName.endsWith("Banner2"),
    };
  }

  componentDidMount() {
    if (this.state.isBanner) {
      this.props.populateBannerGallery(this.props.galleryName);
      const bannerUrl = this.props.banners[this.props.galleryName + "Url"];
      this.setState({
        bannerUrl: bannerUrl,
      });
    } else {
      this.props.populateGallery(this.props.galleryName);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const oldUrl = prevProps.banners[prevProps.galleryName + "Url"];
    const newUrl = this.props.banners[this.props.galleryName + "Url"];
    if (oldUrl == null && newUrl != null) {
      this.setState({
        bannerUrl: newUrl,
      });
    }
  }

  handleChangeLinkData = (e) => {
    e.preventDefault();
    this.setState({
      bannerUrl: e.target.value,
    });
  };

  sendBannerUrlData = (e) => {
    e.preventDefault();
    const bannerUrl = this.state.bannerUrl;
    this.props.SendBannerUrlData(bannerUrl, this.props.galleryName);
    //this.props.history.push("/");
  };
  render() {
    let images = null;
    let BannerForm = null;
    if (this.state.isBanner) {
      images = this.props.banners[this.props.galleryName];
      BannerForm = (
        <Form onSubmit={(event) => this.sendBannerUrlData(event)}>
          <hr />
          <h4>URL must start with http:// or https://</h4>
          <Form.Group controlId="bannerUrl">
            <Form.Label>Banner URL:</Form.Label>
            <Form.Control
              type="text"
              name="bannerUrl"
              value={this.state.bannerUrl}
              onChange={(e) => this.handleChangeLinkData(e)}
              placeholder="Enter Banner url"
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit URL
          </Button>
        </Form>
      );
    } else {
      images = this.props.galleries[this.props.galleryName];
    }
    const displayAndDeleteElement = this.props.displayAndDeleteImages(
      images,
      this.props.galleryName
    );

    return (
      <>
        {displayAndDeleteElement}
        {this.props.auth && this.props.adminButton}
        {BannerForm}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    galleries: state.galleries,
    auth: state.auth.auth,
    banners: state.banners,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    populateGallery: (galleryName) =>
      dispatch(actionCreators.populateGallery(galleryName)),
    populateBannerGallery: (galleryName) =>
      dispatch(actionCreators.populateBannerGallery(galleryName)),
    SendBannerUrlData: (url, type) => {
      dispatch(actionCreators.SendBannerUrlData(url, type));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Gallery, axios));
