import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input";

import emailjs from "emailjs-com";

import { FormGroup, Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const ProductContactForma = (props) => {
  const localStrings = useSelector((state) => state.languages.localStrings);
  const currentLanguage = useSelector(
    (state) => state.languages.currentLanguage
  );
  const [state, setState] = useState({
    human: false,
    disabled: true,
    humanKey: null,
  });

  const verifyCaptcha = (res) => {
    if (res) {
      setState({ human: true, humanKey: res, disabled: false });
    }
  };

  // ReCAPTCHA Expired
  const expireCaptcha = () => {
    setState({ human: false, humanKey: null, disabled: true });
  };

  function sendEmail(e) {
    e.preventDefault();
    const templateParams = {
      contEmail: e.target.contEmail.value,
      contSubj: props.prodName + ": " + e.target.contSubject.value,
      contNum: e.target.contTelephone.value,
      contMsg: e.target.contMessage.value,
    };
    emailjs
      .send(
        "gmail",
        process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          alert("Email sent!"); // LOADER NAPRAVI
          props.history.push("/");
        },
        (error) => {
          alert("Failed to send a message. Try again later.");
        }
      );
  }

  return (
    <Row>
      <Col>
        <Form className="contact__form" onSubmit={(event) => sendEmail(event)}>
          <Row>
            <Col md="6">
              <Row>
                <Col md="12">
                  <FormGroup
                    controlId="contBasicEmail"
                    className="contact-input-row"
                  >
                    <Form.Label className="product-category__label">
                      {localStrings.pages.contact.form.email}*
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={localStrings.pages.contact.form.emailPlcHold}
                      required={true}
                      name="contEmail"
                      maxLength="100"
                      size="60"
                      className="product-category__input"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    controlId="contBasicSubj"
                    className="contact-input-row"
                  >
                    <Form.Label className="product-category__label">
                      {localStrings.pages.contact.form.subject}*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={
                        localStrings.pages.contact.form.subjectPlcHold
                      }
                      required={true}
                      name="contSubject"
                      maxLength="40"
                      size="60"
                      className="product-category__input"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    controlId="contBasicTelephone"
                    className="contact-input-row"
                  >
                    <Form.Label className="product-category__label">
                      {localStrings.pages.jobApplication.form.telephone}*
                    </Form.Label>
                    <PhoneInput
                      international
                      className="product-category__phone-input"
                      defaultCountry="CH"
                      onChange={() => {}}
                      value={state.phone}
                      required
                      countryCallingCodeEditable={false}
                      placeholder={
                        localStrings.pages.jobApplication.form.telephonePlcHold
                      }
                      name="contTelephone"
                      maxLength="100"
                      minLength="7"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <Col md="12">
                  <FormGroup
                    controlId="contBasicMsg"
                    className="contact-input-row"
                  >
                    <Form.Label className="product-category__label">
                      {localStrings.pages.contact.form.message}*
                    </Form.Label>
                    <textarea
                      className="product-category__textarea product-category__input"
                      size="1500"
                      rows="6"
                      placeholder={
                        localStrings.pages.contact.form.messagePlcHold
                      }
                      name="contMessage"
                      maxLength="2000"
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="">
            <Col md="12">
              <FormGroup className="contact-input-centered">
                <ReCAPTCHA
                  hl={currentLanguage === "de" ? "de-CH" : currentLanguage}
                  className="contact__captcha contact-input-centered"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(event) => verifyCaptcha(event)}
                  onExpired={expireCaptcha}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="">
            <Col md="12">
              <FormGroup className="contact-input-centered">
                <Button
                  disabled={state.disabled}
                  variant="dark"
                  type="submit"
                  className="contact-input-centered"
                >
                  {localStrings.pages.contact.form.sendMsg}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default ProductContactForma;
