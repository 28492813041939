import React, { useEffect, useState } from "react";
import commercialSliderPlaceholder from "../../assets/images/logo/commercial-slider-placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import * as actionCreators from "../../store/actions/index";

export default function CommercialSlider({
  ratioLandscape = true,
  galleryName,
}) {
  const auth = useSelector((state) => state.auth.auth);
  const [styles, setStyles] = useState(null);
  const images = useSelector((state) => state.banners[galleryName] || []);

  const galleryNameAndUrl = galleryName + "Url";
  const bannerUrl = useSelector((state) => state.banners[galleryNameAndUrl]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionCreators.populateBannerGallery(galleryName));
  }, [dispatch, galleryName]);

  useEffect(() => {
    if (images.length <= 1) return;

    const setAnimationStyles = () => {
      const styleSheet = document.styleSheets[0];
      const animationName = `commercial-slider-${galleryName}-animation-${Math.round(
        Math.random() * 100
      )}`;

      const renderedArrayLength = images.length + 1;
      const sliderWidthParentPercentage = 100 * renderedArrayLength;
      const singleSlideWidthParentPercentage = 100 / renderedArrayLength;

      const getKeyFrames = () => {
        let keyframes = `@-webkit-keyframes ${animationName} {`;
        // calculate step by actual array length, rather than rendered
        // in order to use repeated element on the end for instant
        // transition to the element on the start (they look the same)
        const keyframeStep = Math.ceil(100 / images.length);
        for (let i = 0; i < renderedArrayLength; i++) {
          if (i !== images.length) {
            keyframes += `${keyframeStep * i}% {-webkit-transform:translateX(${
              -singleSlideWidthParentPercentage * i
            }%)} ${Math.ceil(
              keyframeStep * i + keyframeStep * 0.6
            )}% {-webkit-transform:translateX(${
              -singleSlideWidthParentPercentage * i
            }%)}`;
          } else {
            // last keyframe
            keyframes += `${keyframeStep * i}% {-webkit-transform:translateX(${
              -singleSlideWidthParentPercentage * i
            }%)} 100% {-webkit-transform:translateX(${
              -singleSlideWidthParentPercentage * i
            }%)}`;
          }
        }
        keyframes += "}";
        return keyframes;
      };

      styleSheet.insertRule(getKeyFrames(), styleSheet.cssRules.length);

      return {
        slider: {
          width: sliderWidthParentPercentage + "%",
          animationName: animationName,
          animationTimingFunction: "ease-in-out",
          animationDuration: renderedArrayLength * 5.5 + "s",
          animationDelay: "0",
          animationIterationCount: "infinite",
          animationDirection: "normal",
          animationFillMode: "forwards",
        },
        singleSlide: {
          width: singleSlideWidthParentPercentage + "%",
        },
      };
    };
    setStyles(setAnimationStyles());
  }, [images, galleryName]);

  return (
    <>
      <a
        href={bannerUrl}
        target="_blank"
        rel="noreferrer"
        className={
          "commercial-slider__container " +
          (ratioLandscape
            ? "commercial-slider__landscape"
            : "commercial-slider__portrait")
        }
      >
        <div className="commercial-slider__wrap">
          <div className="commercial-slider__slider" style={styles?.slider}>
            {images?.length > 1 &&
              images.map((imgObj, i) => (
                <div
                  className="commercial-slider__slide"
                  style={{
                    background: `url(${imgObj.image}) center center / cover`,
                    ...styles?.singleSlide,
                  }}
                  key={"commercial-slider-img-" + i}
                ></div>
              ))}

            {/*  one repeated slide, for smooth transition to the
               begining of sliding animation */}
            <div
              className="commercial-slider__slide"
              style={{
                background: `url(${
                  images[0]?.image || commercialSliderPlaceholder
                }) center center / cover`,
                ...styles?.singleSlide,
              }}
            ></div>
          </div>
        </div>
      </a>
      {auth ? (
        <NavLink to={"/edit-gallery/" + galleryName}>
          Click here to change images or URL
        </NavLink>
      ) : null}
    </>
  );
}
