import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EditableText from "../../components/EditableText";
import EditProfilePictureComponent from "../../components/EditProfilePictureComponent";
import { useSelector } from "react-redux";
import GridGallery from "../../components/gallery/GridGallery";

export default function ConsultingAboutPage() {
  const consultingAbout = useSelector(
    (state) => state.languages.localStrings.pages.consulting.about
  );
  const profilePicture = useSelector(
    (state) => state.auth.profilePicture[0].image
  );
  return (
    <div>
      <Container className="consulting__content mb-5">
        <h1 className="page__title pt-5 mb-5">{consultingAbout}</h1>
        <Row>
          <Col>
            <div className="consulting__about__text  editable-text__wrapper">
              <div className="commercial-float-wrap"></div>
              <EditableText textKey="aboutConsulting" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl="2" lg="2" md="3" sm="4" xs="12">
            <img
              className="consulting__about__profilePicture"
              src={profilePicture}
              alt="profile"
            />
            <div>
              <EditProfilePictureComponent image={profilePicture} />
            </div>
          </Col>
          <Col xl="10" lg="10" md="9" sm="8" xs="12">
            <div className="consulting__about__me  editable-text__wrapper">
              <EditableText textKey="aboutConsultingMe" />
            </div>
          </Col>
        </Row>
        <Row>
          <GridGallery galleryName="consultingAboutPageDiplomas" />
        </Row>
      </Container>
    </div>
  );
}
