import * as React from "react"; 
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import useWindowSize from "@rooks/use-window-size";
import ParticleImage, { Vector, forces } from "react-particle-image";
import { Container , Row } from "react-bootstrap";
import ParticlesBg from "particles-bg";
import droneImage from "../../assets/images/products/drone.png";
import laptopImage from "../../assets/images/products/laptop.png";
import spartsImage from "../../assets/images/products/sparts.png";
import tiresImage from "../../assets/images/products/tires.png";

const particleOptions = (width) => ({
  filter: ({ x, y, image }) => {
    // Get pixel
    const pixel = image.get(x, y);
    // Make a particle for this pixel if blue > 50 (range 0-255)
    return pixel.b > 50;
  },
  color: ({ x, y, image }) => "#37afe2",
  radius: () => Math.random() * (0.5 + width / 10000) + (0.5 + width / 10000),
  mass: () => 100,
  friction: () => 0.15,
  initialPosition: ({ canvasDimensions }) => {
    return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2);
  },
});

const motionForce = (x, y) => {
  return forces.disturbance(x, y, 30);
};

const imageLink = (image, to, text) => (
  <NavLink exact={true} to={to} className="products__grid-link">
    <div className="products__frame">
      <div className="products__image-wrap">
        <img src={image} alt={image} className="products__image"></img>
        <div className="products__image-overlay" />
      </div>
      <div className="products__link-text">{text}</div>
    </div>
  </NavLink>
);

const containerWidth = (screenWidth) => {
  if (screenWidth > 1200) return 1140;
  if (screenWidth > 992) return 960;
  if (screenWidth > 768) return 720;
  if (screenWidth > 576) return 540;
  return screenWidth;
};

export default function ProductsPage() {
  const localStrings = useSelector((state) => state.languages.localStrings);
  const { innerWidth } = useWindowSize();
  const width = containerWidth(Number(innerWidth));
  const height = width * 0.4;

  const scale = width / 287;

  return (
    <div className="product-category__wrapper">
      <ParticlesBg
        className="product-category__particles"
        color="#3c94ba"
        num={scale*25}
        type="cobweb"
        bg={true}
      />
      <Container>
        <Row>
          <ParticleImage
            className="products__canvas"
            src={"/shop_online.png"}
            width={width}
            height={height}
            scale={scale * 0.9}
            entropy={6}
            maxParticles={scale * 1200}
            particleOptions={particleOptions(innerWidth)}
            mouseMoveForce={motionForce}
            touchMoveForce={motionForce}
            backgroundColor="#00000000"
          />
        </Row>
        <div className="products__grid mb-3">
          {imageLink(
            droneImage,
            "/rcModels",
            localStrings.pages.products.rcModels
          )}
          {imageLink(
            laptopImage,
            "/electronics",
            localStrings.pages.products.electronics
          )}
          {imageLink(
            spartsImage,
            "/spareParts",
            localStrings.pages.products.spareParts
          )}
          {imageLink(tiresImage, "/tires", localStrings.pages.products.tires)}
        </div>
      </Container>
    </div>
  );
}
