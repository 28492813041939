import React from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../axios-orders";

import Form from "react-bootstrap/Form";
import { Button, Modal, Container } from "react-bootstrap";
import * as actionCreators from "../../../store/actions/index";

class GalleryAddButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      isBanner:
        this.props.galleryName.endsWith("Banner1") ||
        this.props.galleryName.endsWith("Banner2"),
    };
  }

  onCloseEditModal = () => {
    this.setState({
      ...this.state,
      showEditModal: false,
    });
  };

  onShowEditModal = () => {
    this.setState({
      ...this.state,
      showEditModal: true,
    });
  };

  postImage = (e) => {
    e.preventDefault();
    const onError = (error) => {
      console.log(error);
    };
    const onSuccess = () => {
      if (this.state.isBanner) {
        this.props.populateBannerGallery(this.props.galleryName);
      } else {
        this.props.populateGallery(this.props.galleryName);
      }
      alert("Upload was successfull");
    };

    let gallery = null;

    if (this.state.isBanner) {
      gallery = this.props.banners[this.props.galleryName];
    } else {
      gallery = this.props.gallery[this.props.galleryName];
    }
    const imgNameNumberCounter =
      !gallery || gallery.length === 0
        ? 1001
        : gallery[gallery.length - 1].orderNumber + 1;

    const image = document.getElementById("imageToPost").files[0];
    const storageRef = firebase
      .storage()
      .ref(this.props.galleryName + "/" + imgNameNumberCounter);
    storageRef.put(image).then(onSuccess, onError);
  };

  render() {
    const ratioTutorial = (
      <>
        Images should be .jpg or .png and have a 16:9 ratio.
        <br />
        For example: 960x540, 1024x576, 1280x720, 1366x768, 1600x900, 1920x1080,
        2048x1152
        <br />
      </>
    );

    return (
      <>
        {this.state.isBanner ? ratioTutorial : ""}
        <Button variant="primary" onClick={this.onShowEditModal}>
          Add image
        </Button>

        <Modal show={this.state.showEditModal} onHide={this.onCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Choose an image to add</Modal.Title>
          </Modal.Header>
          <Container>
            <Form onSubmit={this.postImage}>
              <Form.Group>
                <Form.File id="imageToPost" label="Upload image!" />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                onClick={this.onCloseEditModal}
              >
                Submit
              </Button>
            </Form>
          </Container>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.onCloseEditModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    gallery: state.galleries,
    banners: state.banners,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    populateGallery: (galleryName) =>
      dispatch(actionCreators.populateGallery(galleryName)),
    populateBannerGallery: (galleryName) =>
      dispatch(actionCreators.populateBannerGallery(galleryName)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(GalleryAddButton, axios));
