import React, { useState } from "react";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-number-input/style.css";
import emailjs from "emailjs-com";
import firebase from "firebase";

import { FormGroup, Button, Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import EditableText from "../components/EditableText";
import logo from "../../assets/images/logo/logo.png";

const JobApplicationPage = (props) => {
  const localStrings = useSelector((state) => state.languages.localStrings);
  const currentLanguage = useSelector(
    (state) => state.languages.currentLanguage
  );

  const [state, setState] = useState({
    human: false,
    disabled: true,
    humanKey: null,
  });

  const verifyCaptcha = (res) => {
    if (res) {
      setState({ human: true, humanKey: res, disabled: false });
    }
  };

  const expireCaptcha = () => {
    setState({ human: false, humanKey: null, disabled: true });
  };

  const onChangePhone = (e) => {};
  const upload = (e) => {
    e.preventDefault();
    const templateParams = {
      appName: e.target.appName.value,
      appLastName: e.target.appLastName.value,
      appSubject: "Job application: " + e.target.appSubject.value,
      link: "",
      appNumber: e.target.appTelephone.value,
      appCover: e.target.appMotive.value,
      appEmail: e.target.appEmail.value,
    };
    const fajl = document.getElementById("cvFile").files[0];
    if (fajl.name.endsWith(".pdf" || ".doc" || "docx")) {
      const storageRef = firebase.storage().ref("CVs/" + fajl.name);
      storageRef.put(fajl).then(
        (snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            templateParams.link = downloadURL;
            emailjs
              .send(
                "gmail",
                process.env.REACT_APP_EMAILJS_TEMPLATE_JOB_APPLICATION_ID,
                templateParams,
                process.env.REACT_APP_EMAILJS_USER_ID
              )
              .then(
                (result) => {
                  alert("Application sent!");
                  props.history.push("/");
                },
                (error) => {
                  //handle noDowloadURL error
                }
              );
          });
        },
        function (error) {
          alert("Application did not succeed. Try again later.");
        }
      );
    } else {
      alert("Not correct file format!");
    }
  };

  const keyboardAnim = (isBottom) => (
    <div
      className={
        "job__keyboard " +
        (isBottom ? "job__keyboard--bottom" : "job__keyboard--left")
      }
    >
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn job__keyboard-btn--wider"></div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn "></div>
      <div className="job__keyboard-btn job__keyboard-btn--enter">
        <span>Apply</span>
      </div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn job__keyboard-btn--wide"></div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn job__keyboard-btn--tall"></div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn"></div>
      <div className="job__keyboard-btn"></div>
    </div>
  );

  return (
    <div className="contact__wrap">
      <Container className="contact__container mb-5">
        <h1 className="page__title pt-5 mb-5">
          {localStrings.pages.jobApplication.title}
        </h1>
        <Row className="mt-3">
          <Col></Col>
          <Col>
            <div className="editable-text__wrapper contact-label">
              <EditableText textKey="jobApplicationStatus" />
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row className="mt-3">
          <Col className="contact__logo-div" md="4">
            <div className="contact__logo-wrapper">
              <img className="contact__logo" src={logo} alt="logo" />
            </div>
            {keyboardAnim(false)}
          </Col>
          <Col md="8">
            <Form className="contact__form" onSubmit={(event) => upload(event)}>
              <Row>
                <Col md="12">
                  <FormGroup
                    className="contact-input-row"
                    controlId="appBasicName"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.jobApplication.form.firstName}*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={
                        localStrings.pages.jobApplication.form.firstNamePlcHold
                      }
                      required={true}
                      name="appName"
                      maxLength="20"
                      size="100"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    className="contact-input-row"
                    controlId="appBasicLastName"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.jobApplication.form.lastName}*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={
                        localStrings.pages.jobApplication.form.lastNamePlcHold
                      }
                      required={true}
                      name="appLastName"
                      maxLength="100"
                      size="60"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    className="contact-input-row"
                    controlId="appBasicEmail"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.jobApplication.form.email}*
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={
                        localStrings.pages.jobApplication.form.emailPlcHold
                      }
                      required={true}
                      name="appEmail"
                      maxLength="100"
                      size="60"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    className="contact-input-row"
                    controlId="appBasicTelephone"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.jobApplication.form.telephone}*
                    </Form.Label>
                    <PhoneInput
                      international
                      defaultCountry="CH"
                      value={state.phone}
                      onChange={(event) => onChangePhone(event)}
                      required={true}
                      countryCallingCodeEditable={false}
                      placeholder={
                        localStrings.pages.jobApplication.form.telephonePlcHold
                      }
                      name="appTelephone"
                      maxLength="100"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    className="contact-input-row"
                    controlId="appBasicSubject"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.jobApplication.form.subject}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={
                        localStrings.pages.jobApplication.form.subjectPH
                      }
                      name="appSubject"
                      maxLength="100"
                      size="60"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    className="contact-input-row"
                    controlId="appBasicMotive"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.jobApplication.form.coverLetter}*
                    </Form.Label>
                    <Form.Control
                      className="contact-textarea"
                      as="textarea"
                      size="55"
                      rows="5"
                      required={true}
                      placeholder={
                        localStrings.pages.jobApplication.form
                          .coverLetterPlcHold
                      }
                      name="appMotive"
                      maxLength="2000"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    className="contact-input-row"
                    controlId="appBasicMotive"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.jobApplication.form.uploadFile}*
                    </Form.Label>
                    <Form.File
                      id="cvFile"
                      required={true}
                      accept=".pdf,.doc,.docx"
                      variant="light"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup className="contact-input-row">
                    <div></div>
                    <ReCAPTCHA
                      className="contact__captcha"
                      hl={currentLanguage === "de" ? "de-CH" : currentLanguage}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={(event) => verifyCaptcha(event)}
                      onExpired={expireCaptcha}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup className="contact-input-row">
                    <div></div>
                    <Button
                      disabled={state.disabled}
                      variant="light"
                      type="submit"
                    >
                      {localStrings.pages.jobApplication.form.submit}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {keyboardAnim(true)}
      </Container>
    </div>
  );
};

export default JobApplicationPage;
