import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavDropdown from "react-bootstrap/NavDropdown";
import { connect } from "react-redux";
import axios from "../../axios-orders";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actionCreators from "../../store/actions/index";

import headerLogo from "../../assets/images/logo/header-logo.png";
import headerLogoText from "../../assets/images/logo/header-logo-text.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: null,
    };
  }

  componentWillMount() {
    this.props.authCheckState();
  }

  toggleNav() {
    this.setState({
      ...this.state,
      navOpen: !this.state.navOpen,
    });
  }

  componentDidMount() {
    this.props.getAvailableLanguages();
    //this.props.setLanguageData(this.props.currentLanguage);
    this.props.getDiplomas();
    this.props.getProfilePicture();
    if (localStorage.getItem("token")) {
      this.props.getAllLanguagesData();
    }
  }

  render() {
    let jezici = [];
    let numOfAvailableLangs = 0;
    for (let jezik in this.props.languages) {
      jezici.push({
        name: this.props.languages[jezik].name,
        available: this.props.languages[jezik].available,
        value: this.props.languages[jezik].value,
      });
      if (this.props.languages[jezik].available){
        numOfAvailableLangs++;
      }
    }
    

    if (this.state.navOpen !== null) {
      if (this.state.navOpen) {
        document
          .querySelector(".app__transition-wrapper")
          .setAttribute("style", "overflow: hidden;");
      } else {
        document
          .querySelector(".app__transition-wrapper")
          .setAttribute("style", "overflow: initial;");
      }
    }

    return (
      <header className="header">
        <Container>
          <Row>
            <Col className="header__content">
              <NavLink
                to="/"
                exact={true}
                className="header__logo-link"
                activeClassName="header__logo-link--active"
                onClick={() => this.state.navOpen && this.toggleNav()}
              >
                <div className="header__logo-wrap">
                  <img className="header__logo" src={headerLogo} alt="logo" />
                  <img
                    className="header__logo-text"
                    src={headerLogoText}
                    alt="logo-text"
                  />
                </div>
              </NavLink>
              <div className="header__spacer"></div>
              <nav
                className={
                  this.state.navOpen
                    ? "header__nav header__nav--on"
                    : "header__nav"
                }
              >
                <ul className="header__nav-ul">
                  <li className="header__nav-li" key={"nav-link-key-home"}>
                    <NavLink
                      exact={true}
                      to={"/"}
                      className="nav-link"
                      activeClassName="nav-link--active"
                      onClick={() => this.state.navOpen && this.toggleNav()}
                    >
                      <span className="nav-link__content">
                        <span className="nav-link__arrow"></span>
                        {this.props.localStrings.navigation["home"]}
                      </span>
                    </NavLink>
                  </li>
                  <li className="header__nav-li" key={"nav-link-key-about"}>
                    <NavLink
                      exact={true}
                      to={"/company"}
                      className="nav-link"
                      activeClassName="nav-link--active"
                      onClick={() => this.state.navOpen && this.toggleNav()}
                    >
                      <span className="nav-link__content">
                        <span className="nav-link__arrow"></span>
                        {this.props.localStrings.navigation["about"]}
                      </span>
                    </NavLink>
                  </li>
                  <li className="header__nav-li" key={"nav-link-key-products"}>
                    <NavLink
                      exact={true}
                      to={"/products"}
                      className="nav-link"
                      activeClassName="nav-link--active"
                      onClick={() => this.state.navOpen && this.toggleNav()}
                    >
                      <span className="nav-link__content">
                        <span className="nav-link__arrow"></span>
                        {this.props.localStrings.navigation["products"]}
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className="header__nav-li"
                    key={"nav-link-key-consulting"}
                  >
                    <NavLink
                      exact={true}
                      to={"/consulting"}
                      className="nav-link"
                      activeClassName="nav-link--active"
                      onClick={() => this.state.navOpen && this.toggleNav()}
                    >
                      <span className="nav-link__content">
                        <span className="nav-link__arrow"></span>
                        {this.props.localStrings.navigation["consulting"]}
                      </span>
                    </NavLink>
                  </li>
                  <li className="header__nav-li" key={"nav-link-key-contact"}>
                    <NavLink
                      exact={true}
                      to={"/contact"}
                      className="nav-link"
                      activeClassName="nav-link--active"
                      onClick={() => this.state.navOpen && this.toggleNav()}
                    >
                      <span className="nav-link__content">
                        <span className="nav-link__arrow"></span>
                        {this.props.localStrings.navigation["contact"]}
                      </span>
                    </NavLink>
                  </li>
                  {!this.props.auth ? (
                    <li className="header__nav-li" key={"nav-link-key-login"}>
                      <NavLink
                        exact={true}
                        to={"/auth"}
                        className="nav-link"
                        activeClassName="nav-link--active"
                        onClick={() => this.state.navOpen && this.toggleNav()}
                      >
                        <span className="nav-link__content">
                          <span className="nav-link__arrow"></span>
                          {this.props.localStrings.navigation["auth"]}
                        </span>
                      </NavLink>
                    </li>
                  ) : (
                    <li className="header__nav-li" key={"nav-link-key-login"}>
                      <NavLink
                        exact={true}
                        to={"/auth"}
                        className="nav-link"
                        activeClassName="nav-link--active"
                        onClick={() => {
                          this.state.navOpen && this.toggleNav();
                          this.props.setAuth(false);
                          localStorage.removeItem("token");
                          localStorage.removeItem("expirationDate");
                        }}
                      >
                        <span className="nav-link__content">
                          <span className="nav-link__arrow"></span>
                          Logout
                        </span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </nav>
              {jezici && (
                <div className="header__language-picker">
                  {(this.props.auth || numOfAvailableLangs>1) ? (
                  <NavDropdown
                    title={this.props.currentLanguage.toUpperCase()}
                    id="language-picker"
                  >
                    {jezici.map((lang) => {
                      if (lang.available) {
                        return (
                          <NavDropdown.Item
                            eventKey={lang.value}
                            key={lang.value}
                            onClick={() =>
                              this.props.setLanguageData(lang.value)
                            }
                          >
                            {lang.name}
                          </NavDropdown.Item>
                        );
                      }
                      return null;
                    })}
                    {this.props.auth ? (
                      <NavDropdown.Item className="header__admin-panel-link">
                        <NavLink to={"/admin-panel"} className="dropdown-item">
                          Admin panel
                        </NavLink>
                      </NavDropdown.Item>
                    ) : null}
                  </NavDropdown>
                  ): null}
                </div>
              )}
              <div
                className={
                  this.state.navOpen
                    ? "header__nav-hamburger header__nav-hamburger--on"
                    : "header__nav-hamburger"
                }
                onClick={() => this.toggleNav()}
              >
                <div className="header__hamburger-line header__hamburger-line--1"></div>
                <div className="header__hamburger-line header__hamburger-line--2"></div>
                <div className="header__hamburger-line header__hamburger-line--3"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languages: state.languages.availableLanguages,
    currentLanguage: state.languages.currentLanguage,
    languageData: state.languages.languageData,
    allLang: state.languages.allLanguagesData,
    localStrings: state.languages.localStrings,
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAvailableLanguages: () =>
      dispatch(actionCreators.getAvailableLanguages()),
    setLanguageData: (language) =>
      dispatch(actionCreators.changeLanguage(language)),
    setAuth: (auth) => dispatch(actionCreators.setAuth(auth)),
    authCheckState: () => dispatch(actionCreators.authCheckState()),
    getDiplomas: () => dispatch(actionCreators.getDiplomas()),
    getProfilePicture: () => dispatch(actionCreators.getProfilePicture()),
    getAllLanguagesData: () => dispatch(actionCreators.getAllLanguagesData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Header, axios));
