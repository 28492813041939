export const SET_AVAILABLE_LANGUAGES = 'SET_AVAILABLE_LANGUAGES';
export const FETCH_AVAILABLE_LANGUAGES_FAILED = 'FETCH_AVAILABLE_LANGUAGES_FAILED';
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';
export const SET_LANGUAGE_DATA = 'SET_LANGUAGE_DATA';
export const SET_AUTH = 'SET_AUTH';
export const FETCH_ALL_LANGUAGE_DATA = 'FETCH_ALL_LANGUAGE_DATA';
export const SET_GALLERY_DATA = 'SET_GALLERY_DATA';
export const SET_LINK_DATA = 'SET_LINK_DATA';
export const SET_BANNERURL_DATA = 'SET_BANNERURL_DATA';
export const SET_DIPLOMAS_DATA = 'SET_DIPLOMAS_DATA';
export const SET_PROFILE_PICTURE_DATA = 'SET_PROFILE_PICTURE_DATA';
export const SET_BANNER_GALLERY_DATA = 'SET_BANNER_GALLERY_DATA';
export const SET_REFERENCE_NAMES = 'SET_REFERENCE_NAMES';
