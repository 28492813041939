import * as actionTypes from "../actions/actionTypes";

const initialState = {
  auth: false,
  consultingVideo: null,
  aboutVideo: null,
  youtube: null,
  facebook: null,
  linkedin: null,
  diplomas: [{}, {}],
  profilePicture: [{}],
};

const setAuth = (state, action) => {
  return { ...state, auth: action.auth };
};

const SetLinkData = (state, action) => {
  return {
    ...state,
    consultingVideo: action.linkData.consultingVideo,
    aboutVideo: action.linkData.aboutVideo,
    youtube: action.linkData.youtube,
    facebook: action.linkData.facebook,
    linkedin: action.linkData.linkedin,
  };
};

const SetDiplomasData = (state, action) => {
  const imagesArray = action.urls.map((url, i) => {
    var imgName = url;
    var splitPath = "consultingAboutPageDiplomas%2F";

    imgName = imgName.split(splitPath)[1];
    imgName = imgName.split("?")[0];
    imgName = imgName.substring(0, 4);
    return {
      id: i,
      name: "Image " + i,
      image: url,
      orderNumber: parseInt(imgName),
    };
  });

  function compareByName(a, b) {
    if (a.orderNumber < b.orderNumber) {
      return -1;
    } else if (a.orderNumber > b.orderNumber) {
      return 1;
    } else {
      return 0;
    }
  }

  imagesArray.sort(compareByName);

  return {
    ...state,
    diplomas: imagesArray,
  };
};

const SetProfilePictureData = (state, action) => {
  const imagesArray = action.urls.map((url, i) => {
    var imgName = url;
    var splitPath = "consultingAboutPageProfilePicture%2F";
    imgName = imgName.split(splitPath)[1];
    imgName = imgName.split("?")[0];
    imgName = imgName.substring(0, 4);
    return {
      id: i,
      name: "Image " + i,
      image: url,
      orderNumber: parseInt(imgName),
    };
  });

  function compareByName(a, b) {
    if (a.orderNumber < b.orderNumber) {
      return -1;
    } else if (a.orderNumber > b.orderNumber) {
      return 1;
    } else {
      return 0;
    }
  }

  imagesArray.sort(compareByName);

  return {
    ...state,
    profilePicture: imagesArray,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH:
      return setAuth(state, action);
    case actionTypes.SET_LINK_DATA:
      return SetLinkData(state, action);
    case actionTypes.SET_DIPLOMAS_DATA:
      return SetDiplomasData(state, action);
    case actionTypes.SET_PROFILE_PICTURE_DATA:
      return SetProfilePictureData(state, action);
    default:
      return state;
  }
};

export default reducer;
