import React from "react";
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import EditableText from "../../components/EditableText";

export default function FormationPage() {
  const aboutTitle = useSelector(
    (state) => state.languages.localStrings.pages.consulting.formation
  );

  return (
    <div className="consulting_formation__wrapper  editable-text__wrapper">
      <Container className="consulting_formation__content">
        <h2 className="page__title pt-5 mb-5">{aboutTitle}</h2>
        <Row>
          <Col>
            <EditableText textKey="formationMain" />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditableText textKey="formationCol1" />
          </Col>
          <Col>
            <EditableText textKey="formationCol2" />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditableText textKey="formationBot" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
