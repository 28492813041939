import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./App";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import languagesReducer from "./store/reducers/languagesReducer";
import authReducer from "./store/reducers/authReducer";
import galleriesReducer from "./store/reducers/galleriesReducer";
import bannersReducer from "./store/reducers/bannersReducer";
import registerServiceWorker from "./registerServiceWorker";
import firebase from "firebase";
import referenceReducer from "./store/reducers/referenceReducer";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "rost-international.firebaseapp.com",
  databaseURL: "https://rost-international.firebaseio.com",
  projectId: "rost-international",
  storageBucket: "rost-international.appspot.com",
  messagingSenderId: "211938882436",
  appId: "1:211938882436:web:3e99313163b74c0c4f63d1",
  measurementId: "G-1QZSZ3B7KN",
});



const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const rootReducer = combineReducers({
  languages: languagesReducer,
  galleries: galleriesReducer,
  auth: authReducer,
  banners: bannersReducer,
  references: referenceReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const render = () =>
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <HashRouter hashType="noslash">
          <App />
        </HashRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );

render();
registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//import reportWebVitals from "./reportWebVitals";
//reportWebVitals();
