// import React from "react";

import Auth from "./views/admin_pages/Auth";
import UploadTermsAndConditionsPage from "./views/admin_pages/UploadTermsAndConditionsPage";
import AdminChangeLanguagesPage from "./views/admin_pages/AdminChangeLanguagesPage";

import HomePage from "./views/pages/home/HomePage";
import CompanyPage from "./views/pages/CompanyPage";
import ProductsPage from "./views/pages/ProductsPage";
import ContactPage from "./views/pages/ContactPage";
import JobApplicationPage from "./views/pages/JobApplicationPage";

import ConsultingPage from "./views/pages/consulting/ConsultingPage";
import ConsultingAboutPage from "./views/pages/consulting/ConsultingAboutPage";
import ReferencesPage from "./views/pages/references/ReferencesPage";
import ConsultingSafetyCulturePage from "./views/pages/consulting/SafetyCulturePage";
import FormationPage from "./views/pages/consulting/FormationPage";

// const HomePage = React.lazy(() => import("./views/pages/home/HomePage"));
// const CompanyPage = React.lazy(() => import("./views/pages/CompanyPage"));
// const ProductsPage = React.lazy(() => import("./views/pages/ProductsPage"));
// const ConsultingPage = React.lazy(() => import("./views/pages/consulting/ConsultingPage"));

const mainRoutes = [
  {
    path: "/",
    exact: true,
    name: "home",
    component: HomePage,
  },
  {
    path: "/company",
    name: "about",
    component: CompanyPage,
  },
  {
    path: "/products",
    name: "products",
    component: ProductsPage,
  },
  {
    path: "/consulting",
    exact: true,
    name: "consulting",
    component: ConsultingPage,
  },
];

const consultingRoutes = [
  {
    path: "/consulting-about",
    name: "Job application",
    component: ConsultingAboutPage,
    languageKeyWord: "about",
  },
  {
    path: "/consulting-safety-culture",
    name: "Job application",
    component: ConsultingSafetyCulturePage,
    languageKeyWord: "safetyCulture",
  },
  {
    path: "/consulting-formation",
    name: "Job application",
    component: FormationPage,
    languageKeyWord: "formation",
  },
  {
    path: "/consulting-references",
    name: "Job application",
    component: ReferencesPage,
    languageKeyWord: "references",
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactPage,
    languageKeyWord: "contact",
  },
];

const otherRoutes = [
  { path: "/contact", name: "contact", component: ContactPage },
  {
    path: "/job-application",
    name: "Job application",
    component: JobApplicationPage,
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/admin-panel",
    name: "AdminPanel",
    component: AdminChangeLanguagesPage,
  },
  {
    path: "/uploadTermsAndConditionsPdf",
    name: "UploadTermsAndConditionsPdf",
    component: UploadTermsAndConditionsPage,
  },
  ...consultingRoutes,
];

export { mainRoutes, otherRoutes, consultingRoutes };
