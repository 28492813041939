import React from "react";
import AnimatedSignSVG from "./animatedSignSvg";
import imgWorker from "../../../assets/images/consulting/workerman.png";

export default function ConsultingSVG() {
  return (
    <svg
      className="home-page__consulting-img"
      viewBox="0 0 400 400"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="pole-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: "rgb(130, 130, 130)" }} />
          <stop offset="33%" style={{ stopColor: "rgb(207, 207, 215)" }} />
          <stop offset="46%" style={{ stopColor: "rgb(123, 123, 139)" }} />
          <stop offset="56%" style={{ stopColor: "rgb(158, 158, 171)" }} />
          <stop offset="100%" style={{ stopColor: "rgb(70, 70, 70)" }} />
        </linearGradient>
        <filter id="shadow-filter2">
          <feDropShadow dx="3" dy="2" stdDeviation="5" floodColor="#404041" />
        </filter>
      </defs>
      <rect
        x="309"
        y="30"
        width="12px"
        height="330px"
        fill="url(#pole-gradient)"
        transform="rotate(3)"
      />
      <AnimatedSignSVG
        x="235"
        y="-135"
        width="150px"
        rotate="3"
        filter="#shadow-filter"
      />
      <image
        className="home-page__worker"
        x="90"
        y="50"
        href={imgWorker}
        width="210px"
        alt="worker"
        style={{ filter: "url(#shadow-filter2)" }}
      />
    </svg>
  );
}
