import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import ReferenceTile from "./ReferenceTile";
import AddReferenceButton from "./AddReferenceButton";
import * as actionCreators from "../../../store/actions/index";
import { Col, Row } from "react-bootstrap";
import EditableText from "../../components/EditableText";

export default function ReferencesPage() {
  const referencesTitle = useSelector(
    (state) => state.languages.localStrings.pages.consulting.references
  );

  const referenceNames = useSelector(
    (state) => state.references.referenceNames
  );

  const auth = useSelector((state) => state.auth.auth);

  const consultingVideo = useSelector((state) => state.auth.consultingVideo);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionCreators.getReferenceNames());
  }, [dispatch]);

  return (
    <div className="references__wrapper">
      <Container className="references__content mb-5">
        <h1 className="page__title pt-5 mb-5">{referencesTitle}</h1>
        {auth && (
          <h5 className="mb-4">
            <b>
              When you delete a reference, you should refresh the page before
              creating a new reference.
            </b>
          </h5>
        )}
        {referenceNames &&
          referenceNames.map((referenceName) => (
            <>
              <ReferenceTile
                referenceNames={referenceNames}
                referenceName={referenceName}
              />
              <hr className="mt-0" />
            </>
          ))}
        {/* Video tile */}
        <Container>
          <EditableText textKey="reference_Video" />
          <div className="iframe-container">
            <iframe
              title="consulting-video"
              width="100%"
              height="auto"
              src={consultingVideo}
            ></iframe>
          </div>
          <hr className="mt-0" />
        </Container>
        {/* End of video tile */}
        <AddReferenceButton referenceNames={referenceNames} />
      </Container>
    </div>
  );
}
