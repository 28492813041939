import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input";
import emailjs from "emailjs-com";

import { FormGroup, Button, Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import logo from "../../assets/images/logo/logo.png";
import EditableText from "../components/EditableText";

const ContactPage = (props) => {
  const localStrings = useSelector((state) => state.languages.localStrings);
  const currentLanguage = useSelector(
    (state) => state.languages.currentLanguage
  );

  const [state, setState] = useState({
    human: false,
    disabled: true,
    humanKey: null,
  });

  const verifyCaptcha = (res) => {
    if (res) {
      setState({ human: true, humanKey: res, disabled: false });
    }
  };

  // ReCAPTCHA Expired
  const expireCaptcha = () => {
    setState({ human: false, humanKey: null, disabled: true });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    var templateParams = {
      contEmail: e.target.contEmail.value,
      contSubj: e.target.contSubject.value,
      contNum: e.target.contTelephone.value,
      contMsg: e.target.contMessage.value,
    };
    emailjs
      .send(
        "gmail",
        process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          alert("Application sent!"); // LOADER NAPRAVI
          props.history.push("/");
        },
        (error) => {
          alert("Failed to send a message. Try again later.");
        }
      );
  };

  return (
    <div className="contact__wrap">
      <Container>
        <h1 className="page__title pt-5 mb-5">
          {localStrings.pages.contact.title}
        </h1>
        <Row className="mt-3">
          <Col md="4">
            <Row className="mb-3">
              <div className="contact__logo-wrapper mx-auto ">
                <img className="contact__logo" src={logo} alt="logo" />
              </div>
            </Row>
            <Row className="mb-3 justify-content-center">
              <Col>
                <div className="contact__info">
                  <EditableText textKey="contactPage" />
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="8">
            <Form
              className="contact__form"
              onSubmit={(event) => sendEmail(event)}
            >
              <Row>
                <Col md="12">
                  <FormGroup
                    controlId="contBasicEmail"
                    className="contact-input-row"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.contact.form.email}*
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={localStrings.pages.contact.form.emailPlcHold}
                      required={true}
                      name="contEmail"
                      maxLength="100"
                      size="60"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    controlId="contBasicSubj"
                    className="contact-input-row"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.contact.form.subject}*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={
                        localStrings.pages.contact.form.subjectPlcHold
                      }
                      required={true}
                      name="contSubject"
                      maxLength="40"
                      size="60"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    controlId="contBasicTelephone"
                    className="contact-input-row"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.jobApplication.form.telephone}*
                    </Form.Label>
                    <PhoneInput
                      international
                      defaultCountry="CH"
                      value={state.phone}
                      onChange={() => {}}
                      required={true}
                      countryCallingCodeEditable={false}
                      placeholder={
                        localStrings.pages.jobApplication.form.telephonePlcHold
                      }
                      name="contTelephone"
                      maxLength="100"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup
                    controlId="contBasicMsg"
                    className="contact-input-row"
                  >
                    <Form.Label className="contact-label">
                      {localStrings.pages.contact.form.message}*
                    </Form.Label>
                    <textarea
                      className="contact-textarea"
                      size="1500"
                      rows="6"
                      placeholder={
                        localStrings.pages.contact.form.messagePlcHold
                      }
                      name="contMessage"
                      maxLength="2000"
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="">
                <Col md="12">
                  <FormGroup className="contact-input-row">
                    <div></div>
                    <ReCAPTCHA
                      hl={currentLanguage === "de" ? "de-CH" : currentLanguage}
                      className="contact__captcha"
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={(event) => verifyCaptcha(event)}
                      onExpired={expireCaptcha}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="">
                <Col md="12">
                  <FormGroup className="contact-input-row">
                    <div></div>
                    <Button
                      disabled={state.disabled}
                      variant="light"
                      type="submit"
                    >
                      {localStrings.pages.contact.form.sendMsg}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <div className="contact__map mt-2">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2763.105890573886!2d8.78454561580426!3d46.16854779418861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4785c8301847977b%3A0x9c3e75fc323cd208!2sROST%20International!5e0!3m2!1sen!2srs!4v1612027695081!5m2!1sen!2srs"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactPage;
