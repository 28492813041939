import React, { Component } from "react";
import { Button, Modal, FormGroup } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import Form from "react-bootstrap/Form";

export default class EditModalComponent extends Component {
  state = {
    show: true,
  };

  modalInitSettings = {
    height: 200,
    menubar: false,
    plugins: [
      "advlist autolink lists link image",
      "charmap print preview anchor help",
      "searchreplace visualblocks code",
      "insertdatetime media table paste wordcount",
      "textcolor",
    ],
    toolbar: `| undo redo | sizeselect | fontsizeselect | bold italic | bullist numlist | forecolor backcolor | link`,
    default_link_target: "_blank",
  };
  apiKey = "xx7z41mhoyvtoiutgik6642xe9usnzppvz9i5rch6y1bqvrj";

  render() {
    return (
      <Modal
        animation={false}
        show={this.state.show}
        onHide={() => {
          this.setState({ show: false });
          this.props.handleClose();
        }}
        backdrop="static"
        keyboard={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup controlId="appBasicLastName">
              <Form.Label>English</Form.Label>
              <Editor
                apiKey={this.apiKey}
                initialValue={this.props.english}
                init={this.modalInitSettings}
                onChange={(e) => this.props.handleEditorChange(e, "en")}
              />
              <Form.Label>Italian</Form.Label>
              <Editor
                apiKey={this.apiKey}
                initialValue={this.props.italian}
                init={this.modalInitSettings}
                onChange={(e) => this.props.handleEditorChange(e, "it")}
              />

              <Form.Label>French</Form.Label>
              <Editor
                apiKey={this.apiKey}
                initialValue={this.props.french}
                init={this.modalInitSettings}
                onChange={(e) => this.props.handleEditorChange(e, "fr")}
              />

              <Form.Label>German</Form.Label>
              <Editor
                apiKey={this.apiKey}
                initialValue={this.props.german}
                init={this.modalInitSettings}
                onChange={(e) => this.props.handleEditorChange(e, "de")}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              this.setState({ show: false });
              this.props.handleClose();
            }}
          >
            Close
          </Button>

          <Button
            variant="primary"
            type="submit"
            onClick={this.props.redirectToPreviewComponent}
          >
            Preview
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
