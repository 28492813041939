import React from "react";
import { Button, Container, Modal, Row, Col } from "react-bootstrap";

export default function ConfirmableButton(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Button
        variant="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.buttonText}
      </Button>
      <Modal
        animation={false}
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        backdrop="static"
        keyboard={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.dialogTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">{props.dialogText}</Row>
            <Row>
              <Col></Col>
              <Col>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  variant="primary"
                >
                  {props.cancelText}
                </Button>
              </Col>
              <Col></Col>
              <Col>
                <Button
                  onClick={() => {
                    props.action();
                    setOpen(false);
                  }}
                  variant="primary"
                  autoFocus
                >
                  {props.confirmText}
                </Button>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
