import React from "react";
import { Component } from "react";
import { Modal } from "react-bootstrap";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
  IoIosCloseCircle,
} from "react-icons/io";

class GalleryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImg: null,
    };
  }

  onCloseLightBoxModal = () => {
    this.setState({
      ...this.state,
      currentImg: null,
    });
  };

  onShowLightBoxModal = (imageObj) => {
    this.setState((state) => ({
      ...state,
      currentImg: { ...imageObj },
    }));
  };

  onNextImg = () => {
    const followingImgs = this.props.images.filter(
      (item) => item.orderNumber > this.state.currentImg.orderNumber
    );

    if (followingImgs) {
      const nextOrderNumber = Math.min.apply(
        Math,
        followingImgs.map(function (o) {
          return o.orderNumber;
        })
      );

      const nextImage = followingImgs.find(function (o) {
        return o.orderNumber === nextOrderNumber;
      });

      this.setState((state) => ({
        ...state,
        currentImg: nextImage,
      }));
    } else {
      this.onCloseLightBoxModal();
    }
  };

  onPreviousImg = () => {
    const prevImgs = this.props.images.filter(
      (item) => item.orderNumber < this.state.currentImg.orderNumber
    );

    if (prevImgs) {
      const prevOrderNumber = Math.max.apply(
        Math,
        prevImgs.map(function (o) {
          return o.orderNumber;
        })
      );

      const previousImage = prevImgs.find(function (o) {
        return o.orderNumber === prevOrderNumber;
      });

      this.setState({
        ...this.state,
        currentImg: previousImage,
      });
    } else {
      this.onCloseLightBoxModal();
    }
  };

  reloadSrc = (e) => {
    e.target.src = this.state.currentImg.image;
  };

  render() {
    const mainDisplay = this.props.mainDisplay(this.onShowLightBoxModal);

    return (
      <>
        {mainDisplay}
        {!!this.state.currentImg && (
          <Modal
            className="gallery__lightbox"
            show={!!this.state.currentImg}
            onHide={this.onCloseLightBoxModal}
          >
            <div className="gallery__lightbox-img-wrap">
              <img
                className="gallery__lightbox-img"
                src={this.state.currentImg.image}
                alt={this.state.currentImg.name}
                key="lightbox-img"
                onError={this.reloadSrc}
              />
            </div>
            <button
              className="gallery__lightbox-close gallery__lightbox-btn"
              onClick={this.onCloseLightBoxModal}
            >
              <IoIosCloseCircle />
            </button>
            <button
              className="gallery__lightbox-previous gallery__lightbox-btn gallery__lightbox-arrow"
              onClick={this.onPreviousImg}
            >
              <IoIosArrowDropleftCircle />
            </button>
            <button
              className="gallery__lightbox-next gallery__lightbox-btn gallery__lightbox-arrow"
              onClick={this.onNextImg}
            >
              <IoIosArrowDroprightCircle />
            </button>
          </Modal>
        )}
      </>
    );
  }
}

export default GalleryModal;
