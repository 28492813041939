import { Switch, Route, useLocation, withRouter } from "react-router-dom";
import { useTransition, animated } from "react-spring";

import Header from "./views/layout/header";
import Footer from "./views/layout/footer";
import { mainRoutes, otherRoutes } from "./routes";

import "./scss/style.scss";
import ProductCategoryPage from "./views/pages/product_category/ProductCategoryPage";
import GridGallery from "./views/components/gallery/GridGallery";

import React, { useEffect } from 'react';
import ReactGA from "react-ga";

function App() {

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
    ReactGA.pageview("/");
  }, [])

  let location = useLocation();
  const transitions = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <div className="App">
      <Header routes={mainRoutes} />
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          style={props}
          className="app__transition-wrapper"
        >
          <main className="app__content">
            <Switch location={item}>
              {mainRoutes.map((route, i) => {
                return (
                  route.component && (
                    <Route
                      key={"main-route-key-" + i}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  )
                );
              })}
              {otherRoutes.map((route, i) => {
                return (
                  route.component && (
                    <Route
                      key={"other-route-key-" + i}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  )
                );
              })}
              <Route
                key={"spareParts-route-key"}
                path={"/spareParts"}
                exact={true}
                render={(props) => (
                  <ProductCategoryPage
                    {...props}
                    productCategory="spareParts"
                  />
                )}
              />
              <Route
                key={"tires-route-key"}
                path={"/tires"}
                exact={true}
                render={(props) => (
                  <ProductCategoryPage {...props} productCategory="tires" />
                )}
              />
              <Route
                key={"electronics-route-key"}
                path={"/electronics"}
                exact={true}
                render={(props) => (
                  <ProductCategoryPage
                    {...props}
                    productCategory="electronics"
                  />
                )}
              />
              <Route
                key={"rcModels-route-key"}
                path={"/rcModels"}
                exact={true}
                render={(props) => (
                  <ProductCategoryPage {...props} productCategory="rcModels" />
                )}
              />
              <Route
                key={"edit-gallery-route-key"}
                path={"/edit-gallery/:galleryName"}
                exact={true}
                render={(props) => (
                  <GridGallery
                    {...props}
                    galleryName={props.match.params.galleryName}
                  />
                )}
              />
            </Switch>
          </main>
          <Footer />
        </animated.div>
      ))}
    </div>
  );
}

export default withRouter(App);
