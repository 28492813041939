import * as actionTypes from "../actions/actionTypes";

const initialState = {
  RCModelsGalleryBanner1: [],
  RCModelsGalleryBanner2: [],
  tiresGalleryBanner1: [],
  tiresGalleryBanner2: [],
  sparePartsGalleryBanner1: [],
  sparePartsGalleryBanner2: [],
  consultingGalleryBanner1: [],
  consultingGalleryBanner2: [],
  electronicsGalleryBanner1: [],
  electronicsGalleryBanner2: [],
  RCModelsGalleryBanner1Url: null,
  RCModelsGalleryBanner2Url: null,
  tiresGalleryBanner1Url: null,
  tiresGalleryBanner2Url: null,
  sparePartsGalleryBanner1Url: null,
  sparePartsGalleryBanner2Url: null,
  consultingGalleryBanner1Url: null,
  consultingGalleryBanner2Url: null,
  electronicsGalleryBanner1Url: null,
  electronicsGalleryBanner2Url: null,
};

const SetBannerUrlData = (state, action) => {
  return {
    ...state,
    RCModelsGalleryBanner1Url: action.BannerUrlData.RCModelsGalleryBanner1,
    RCModelsGalleryBanner2Url: action.BannerUrlData.RCModelsGalleryBanner2,
    tiresGalleryBanner1Url: action.BannerUrlData.tiresGalleryBanner1,
    tiresGalleryBanner2Url: action.BannerUrlData.tiresGalleryBanner2,
    sparePartsGalleryBanner1Url: action.BannerUrlData.sparePartsGalleryBanner1,
    sparePartsGalleryBanner2Url: action.BannerUrlData.sparePartsGalleryBanner2,
    consultingGalleryBanner1Url: action.BannerUrlData.consultingGalleryBanner1,
    consultingGalleryBanner2Url: action.BannerUrlData.consultingGalleryBanner2,
    electronicsGalleryBanner1Url:
      action.BannerUrlData.electronicsGalleryBanner1,
    electronicsGalleryBanner2Url:
      action.BannerUrlData.electronicsGalleryBanner2,
  };
};

const setGalleries = (state, action) => {
  const imagesArray = action.urls.map((url, i) => {
    let imgName = url;
    const splitPath = action.galleryName + "%2F";

    imgName = imgName.split(splitPath)[1];
    imgName = imgName.split("?")[0];
    imgName = imgName.substring(0, 4);
    return {
      id: i,
      name: "Image " + i,
      image: url,
      orderNumber: parseInt(imgName),
    };
  });

  function compareByName(a, b) {
    if (a.orderNumber < b.orderNumber) {
      return -1;
    } else if (a.orderNumber > b.orderNumber) {
      return 1;
    } else {
      return 0;
    }
  }

  imagesArray.sort(compareByName);

  switch (action.galleryName) {
    case "RCModelsGalleryBanner1":
      return {
        ...state,
        RCModelsGalleryBanner1: imagesArray,
      };
    case "RCModelsGalleryBanner2":
      return {
        ...state,
        RCModelsGalleryBanner2: imagesArray,
      };
    case "tiresGalleryBanner1":
      return {
        ...state,
        tiresGalleryBanner1: imagesArray,
      };
    case "tiresGalleryBanner2":
      return {
        ...state,
        tiresGalleryBanner2: imagesArray,
      };
    case "sparePartsGalleryBanner1":
      return {
        ...state,
        sparePartsGalleryBanner1: imagesArray,
      };
    case "sparePartsGalleryBanner2":
      return {
        ...state,
        sparePartsGalleryBanner2: imagesArray,
      };
    case "consultingGalleryBanner1":
      return {
        ...state,
        consultingGalleryBanner2: imagesArray,
      };
    case "consultingGalleryBanner2":
      return {
        ...state,
        consultingGalleryBanner2: imagesArray,
      };
    case "electronicsGalleryBanner1":
      return {
        ...state,
        electronicsGalleryBanner1: imagesArray,
      };
    case "electronicsGalleryBanner2":
      return {
        ...state,
        electronicsGalleryBanner2: imagesArray,
      };
    default:
      console.log("Default redux return");
      break;
  }
  return { ...state };
};

const bannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_GALLERY_DATA:
      return setGalleries(state, action);
    case actionTypes.SET_BANNERURL_DATA:
      return SetBannerUrlData(state, action);
    default:
      return state;
  }
};

export default bannersReducer;
