import * as actionTypes from "./actionTypes";
import firebase from "firebase";

export const setGalleryData = (galleryName, urls) => {
  return {
    type: actionTypes.SET_GALLERY_DATA,
    galleryName,
    urls,
  };
};

const emptyFunc = () => {
  alert("Delete was successfull");
};

const errorLog = (error) => {
  console.log(error);
};

export const addPicureToGallery = (image, path) => {
  var storageRef = firebase.storage().ref(path + "/" + image.name);
  storageRef.put(image).then(emptyFunc, errorLog);
};

export const removePictureFromGallery = (url, folderName) => {
  var imgName = url;
  var splitPath = folderName + "%2F";
  imgName = imgName.split(splitPath)[1];
  imgName = imgName.split("?")[0];
  var desertRef = firebase
    .storage()
    .ref()
    .child(folderName + "/" + imgName);
  desertRef.delete().then(emptyFunc, errorLog);
};

export const populateGallery = (galleryName) => {
  const folderRef = firebase.storage().ref(galleryName);
  let numberOfImageRefs = 0;
  const urls = [];

  return (dispatch) => {
    const getDownloadUrl = (imageRef) => {
      imageRef
        .getDownloadURL()
        .then((url) => {
          urls.push(url);
          if (urls.length === numberOfImageRefs) {
            dispatch(setGalleryData(galleryName, urls));
          }
        })
        .catch(errorLog);
    };
    folderRef
      .listAll()
      .then((result) => {
        numberOfImageRefs = result.items.length;
        result.items.forEach((imageRef) => {
          getDownloadUrl(imageRef);
        });
      })
      .catch(errorLog);
  };
};
