import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../../axios-orders";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import { consultingRoutes } from "../../../routes";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import consultingImg1 from "../../../assets/images/consulting/consulting-1.jpg";
import consultingImg6 from "../../../assets/images/consulting/consulting-6.jpg";

class ConsultingPage extends Component {
  images = [consultingImg1, consultingImg6];

  setAnimationStyles = () => {
    const styleSheet = document.styleSheets[0];
    const animationName = `background-slider-animation-${Math.round(
      Math.random() * 100
    )}`;

    const renderedArrayLength = this.images.length + 1;
    const sliderWidthParentPercentage = 100 * renderedArrayLength;
    const singleSlideWidthParentPercentage = 100 / renderedArrayLength;

    const getKeyFrames = () => {
      let keyframes = `@-webkit-keyframes ${animationName} {`;
      // calculate step by actual array length, rather than rendered
      // in order to use repeated element on the end for instant
      // transition to the element on the start (they look the same)
      const keyframeStep = Math.ceil(100 / this.images.length);
      for (let i = 0; i < renderedArrayLength; i++) {
        if (i !== this.images.length) {
          keyframes += `${keyframeStep * i}% {-webkit-transform:translateX(${
            -singleSlideWidthParentPercentage * i
          }%)} ${Math.ceil(
            keyframeStep * i + keyframeStep * 0.6
          )}% {-webkit-transform:translateX(${
            -singleSlideWidthParentPercentage * i
          }%)}`;
        } else {
          // last keyframe
          keyframes += `${keyframeStep * i}% {-webkit-transform:translateX(${
            -singleSlideWidthParentPercentage * i
          }%)} 100% {-webkit-transform:translateX(${
            -singleSlideWidthParentPercentage * i
          }%)}`;
        }
      }
      keyframes += "}";
      return keyframes;
    };

    styleSheet.insertRule(getKeyFrames(), styleSheet.cssRules.length);

    return {
      slider: {
        width: sliderWidthParentPercentage + "%",
        animationName: animationName,
        animationTimingFunction: "ease-in-out",
        animationDuration: renderedArrayLength * 5.5 + "s",
        animationDelay: "0",
        animationIterationCount: "infinite",
        animationDirection: "normal",
        animationFillMode: "forwards",
      },
      singleSlide: {
        width: singleSlideWidthParentPercentage + "%",
      },
    };
  };

  render() {
    const consultingStrings = this.props.localStrings.pages.consulting;
    const styles = this.setAnimationStyles();

    return (
      <div className="consulting__container">
        <Container>
          <h1 className="page__title pt-5 mb-5">{consultingStrings.title}</h1>
        </Container>
        <Container className="consulting__content">
          <Row>
            <Col>
              <div className="consulting__grid">
                {consultingRoutes.map((route, i) => (
                  <NavLink
                    className="consulting__link"
                    key={route.name + i}
                    exact={route.exact}
                    to={route.path}
                  >
                    <h2>{consultingStrings[route.languageKeyWord]}</h2>
                  </NavLink>
                ))}
              </div>
            </Col>
          </Row>
        </Container>

        <div className="consulting__background__wrap">
          <div className="consulting__background__slider" style={styles.slider}>
            {this.images.map((img, i) => (
              <div
                className="consulting__background__slide"
                style={{
                  background: `url(${img})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  ...styles.singleSlide,
                }}
                key={"consulting-bkg-" + i}
              ></div>
            ))}

            {/*  one repeated slide, for smooth transition to the
                 begining of sliding animation */}
            <div
              className="consulting__background__slide"
              style={{
                background: `url(${this.images[0]})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                ...styles.singleSlide,
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageData: state.languages.languageData,
    localStrings: state.languages.localStrings,
  };
};

export default connect(mapStateToProps)(
  withErrorHandler(ConsultingPage, axios)
);
