import React from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import imgLogo from "../../assets/images/logo/logo.png";
import EditableText from "../components/EditableText";

export default function CompanyPage() {
  const aboutTitle = useSelector(
    (state) => state.languages.localStrings.navigation.about
  );

  const aboutVideo = useSelector((state) => state.auth.aboutVideo);

  return (
    <div className="company__wrapper  editable-text__wrapper">
      <Container className="company__content">
        <h1 className="page__title pt-5 mb-5">{aboutTitle}</h1>
        <span className="company__logo-wrapper">
          <img className="company__logo" src={imgLogo} alt="company logo" />
        </span>
        <EditableText textKey="aboutPage" />
      </Container>
    </div>
  );
}
