import React, { Component } from "react";
import { Button, Modal, Container } from "react-bootstrap";

import firebase from "firebase";
import Form from "react-bootstrap/Form";

export default class UploadTermsAndConditionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  postPdf = (e) => {
    const onSuc = () => {
      alert("Successfully uploaded PDF");
    };
    const onEror = (error) => {};
    e.preventDefault();
    const pdf = document.getElementById("pdfToPost").files[0];
    if (!pdf) {
      alert("No pdf selected!");
      return;
    }
    const storageRef = firebase.storage().ref("static/RostTermsAndConditions.pdf");
    storageRef.put(pdf).then(onSuc, onEror);
  };

  deletePdf = (e) => {
    const onSuc = () => {
      alert("Successfully deleted PDF");
    };
    const onError = (error) => {
      alert("There is no PDF to be deleted");
    };
    e.preventDefault();
    const storageRef = firebase.storage().ref("static/RostTermsAndConditions.pdf");
    storageRef.delete().then(onSuc, onError);
  }

  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = () => {
    this.setState({
      show: true,
    });
  };
  render() {
    return (
      <div>
        <Container>
        <h1>Terms and Conditions admin panel</h1>

        <Button variant="primary" onClick={this.handleShow}>
          Upload PDF
        </Button>
         &nbsp; &nbsp;  &nbsp;  
        <Button variant="primary" onClick={this.deletePdf}>
            Delete PDF
          </Button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload PDF</Modal.Title>
          </Modal.Header>
          <Container>
            <Form onSubmit={this.postPdf}>
              <Form.Group>
                <Form.File id="pdfToPost" label="Upload pdf!" />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                onClick={this.handleClose}
              >
                Submit
              </Button>
            </Form>
          </Container>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        </Container>
      </div>
    );
  }
}
