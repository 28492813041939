import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EditableText from "../../components/EditableText";
import ReferenceGallery from "./ReferenceGallery";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../../store/actions/index";
import ConfirmableButton from "../../components/ConfirmationDialog";

export default function ReferenceTile(props) {
  const prefixedReferenceName = "reference_" + props.referenceName;
  const auth = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();
  const handleConfirm = () => {
    dispatch(
      actionCreators.deleteReference(props.referenceNames, props.referenceName)
    );
    dispatch(actionCreators.getReferenceNames());
  };

  return (
    <Row>
      <Col>
        <div className="editable-text__wrapper">
          <span className="references__img-wrapper">
            <ReferenceGallery galleryName={prefixedReferenceName} />
          </span>
          <EditableText textKey={prefixedReferenceName} />
          {auth && (
            <span className="references__delete-btn">
              <ConfirmableButton
                buttonText="Delete reference"
                dialogTitle="Are you sure?"
                dialogText="If you delete the reference, you will lose all the images and text that it contains. This cannot be undone."
                cancelText="Cancel"
                confirmText="Delete"
                action={handleConfirm}
              />
            </span>
          )}
        </div>
      </Col>
    </Row>
  );
}
