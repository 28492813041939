import * as actionTypes from "./actionTypes";
import firebase from "firebase";
import axios from "../../axios-orders";


export const setBannerGalleryData = (galleryName, urls) => {
    return {
      type: actionTypes.SET_GALLERY_DATA,
      galleryName,
      urls,
    };
  };

  export const addPictureToBannerGallery = (image, path) => {
    let onsuc = function () {
      alert("Upload was successfull");
    };
    let oneror = function (error) {};
  
    var storageRef = firebase.storage().ref(path + "/" + image.name);
    storageRef.put(image).then(onsuc, oneror);
  };
  
  export const removePictureFromBannerGallery = (url, folderName) => {
    let onsuc = function () {
      alert("Delete was successfull");
    };
    let oneror = function (error) {};
  
    var imgName = url;
    var splitPath = folderName + "%2F";
    imgName = imgName.split(splitPath)[1];
    imgName = imgName.split("?")[0];
    // var desertRef = firebase.storage().ref().child('pajoslav/' + imgName);
    var desertRef = firebase
      .storage()
      .ref()
      .child(folderName + "/" + imgName);
    desertRef.delete().then(onsuc, oneror);
  };

export const populateBannerGallery = (galleryName) => {
    const folderRef = firebase.storage().ref(galleryName);
    let numberOfImageRefs = 0;
    const urls = [];
  
    return (dispatch) => {
      const getDownloadUrl = (imageRef) => {
        imageRef
          .getDownloadURL()
          .then((url) => {
            urls.push(url);
            if (urls.length === numberOfImageRefs) {
              dispatch(setBannerGalleryData(galleryName, urls));
            }
          })
          .catch((error) => {
            // Handle any errors
          });
      };
      folderRef
        .listAll()
        .then((result) => {
          numberOfImageRefs = result.items.length;
          result.items.forEach((imageRef) => {
            getDownloadUrl(imageRef);
          });
        })
        .catch((error) => {
          // Handle any errors
        });
    };
};
  

export const initBannerUrlData = () => {
    return (dispatch) => {
      axios
        .get("https://rost-international.firebaseio.com/banners.json")
        .then((response) => {
          dispatch(SetBannerUrlData(response.data));
        })
        .catch((error) => {});
    };
  };
  
  export const SetBannerUrlData = (BannerUrlData) => {
    return {
      type: actionTypes.SET_BANNERURL_DATA,
      BannerUrlData,
    };
};
  

export const SendBannerUrlData = (url, type) => {
    const token = localStorage.getItem("token");
    return (dispatch) => {
      axios
        .patch(
          "https://rost-international.firebaseio.com/banners.json?auth=" + token,
          {[type]:url}
        )
        .then((response) => {
          dispatch(SetBannerUrlData(response.data));
          alert('Banner URL have successfully uploaded');
          console.log(response.data);
        })
        .catch((error) => {
          // dispatch(fetchAvailableLanguagesFailed());
        });
    };
  };
  