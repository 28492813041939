import React, { Component } from "react";
import { connect } from "react-redux";

import { Col, Container, Row, Button, Form } from "react-bootstrap";
import * as actions from "../../store/actions/index";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../axios-orders";

export class AdminChangeLanguagesPage extends Component {
  state = {
    de: this.props.languageData.de.available,
    fr: this.props.languageData.fr.available,
    it: this.props.languageData.it.available,
    en: this.props.languageData.en.available,
    facebook: this.props.facebook,
    linkedin: this.props.linkedin,
    consultingVideo: this.props.consultingVideo,
    aboutVideo: this.props.aboutVideo,
    youtube: this.props.youtube,
  };

  handleChangeLinkData = (e, type) => {
    e.preventDefault();
    switch (type) {
      case "facebook":
        this.setState({
          facebook: e.target.value,
        });
        break;
      case "linkedin":
        this.setState({
          linkedin: e.target.value,
        });
        break;
      case "consultingVideo":
        this.setState({
          consultingVideo: e.target.value,
        });
        break;
      case "aboutVideo":
        this.setState({
          aboutVideo: e.target.value,
        });
        break;
      case "youtube":
        this.setState({
          youtube: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  sendLinkData = (e) => {
    e.preventDefault();
    // var linkDataUrls = {
    //   facebook: e.target.facebook.value,
    //   linkedin: e.target.linkedin.value,
    //   youtube: e.target.youtube.value,
    //   consultingVideo: e.target.consultingVideo.value,
    //   aboutVideo: e.target.aboutVideo.value,
    // };
    var linkDataUrls = {
      facebook: this.state.facebook,
      linkedin: this.state.linkedin,
      youtube: this.state.youtube,
      consultingVideo: this.state.consultingVideo,
      aboutVideo: this.state.aboutVideo,
    };
    this.props.SendLinkData(linkDataUrls);
    this.props.history.push("/");
  };

  submitToFirebaseAdminAvailableLanguages = (event) => {
    event.preventDefault();
    let tempAllLanguagesData = this.props.languageData;
    tempAllLanguagesData.de.available = this.state.de;
    tempAllLanguagesData.it.available = this.state.it;
    tempAllLanguagesData.en.available = this.state.en;
    tempAllLanguagesData.fr.available = this.state.fr;

    this.props.postLanguagesToFirebase(tempAllLanguagesData);
    alert("Language panel is successfully changed");
    this.props.history.push("/");
  };

  handleChange = (e, type) => {
    switch (type) {
      case "en":
        this.setState({
          en: e.target.checked,
        });
        break;
      case "it":
        this.setState({
          it: e.target.checked,
        });
        break;
      case "de":
        this.setState({
          de: e.target.checked,
        });
        break;
      case "fr":
        this.setState({
          fr: e.target.checked,
        });
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <Container>
        <Row>
          <Col style={{ margin: "50px 0" }}>
            <form onSubmit={this.submitToFirebaseAdminAvailableLanguages}>
              <input
                type="checkbox"
                id="English"
                name="English"
                onChange={(event) => this.handleChange(event, "en")}
                checked={this.state.en}
              />
              &nbsp;&nbsp;
              <label htmlFor="English">English</label>
              <br></br>
              <input
                type="checkbox"
                id="Italian"
                name="Italian"
                onChange={(event) => this.handleChange(event, "it")}
                checked={this.state.it}
              />{" "}
              &nbsp;&nbsp;
              <label htmlFor="Italian">Italian</label>
              <br></br>
              <input
                type="checkbox"
                id="French"
                name="French"
                onChange={(event) => this.handleChange(event, "fr")}
                checked={this.state.fr}
              />
              &nbsp;&nbsp;
              <label htmlFor="French">French</label>
              <br></br>
              <input
                type="checkbox"
                id="German"
                name="German"
                onChange={(event) => this.handleChange(event, "de")}
                checked={this.state.de}
              />
              &nbsp;&nbsp;
              <label htmlFor="German">German</label>
              <br></br>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </form>
            <br />
            <hr />
            <br />

            <h4>URL must start with http:// or https://</h4>
            <Form onSubmit={(event) => this.sendLinkData(event)}>
              <Form.Group controlId="facebook">
                <Form.Label>Facebook</Form.Label>
                <Form.Control
                  type="text"
                  name="facebook"
                  value={this.state.facebook}
                  onChange={(e) => this.handleChangeLinkData(e, "facebook")}
                  placeholder="Enter Facebook url"
                />
              </Form.Group>
              <Form.Group controlId="linkedin">
                <Form.Label>Linkedin</Form.Label>
                <Form.Control
                  type="text"
                  name="linkedin"
                  value={this.state.linkedin}
                  onChange={(e) => this.handleChangeLinkData(e, "linkedin")}
                  placeholder="Enter Linkedin url"
                />
              </Form.Group>
              <Form.Group controlId="youTube">
                <Form.Label>YouTube</Form.Label>
                <Form.Control
                  type="text"
                  name="youtube"
                  value={this.state.youtube}
                  onChange={(e) => this.handleChangeLinkData(e, "youtube")}
                  placeholder="Enter YouTube url"
                />
              </Form.Group>
              <Form.Group controlId="consultingVideo">
                <Form.Label>Consulting video</Form.Label>
                <Form.Control
                  type="text"
                  name="consultingVideo"
                  value={this.state.consultingVideo}
                  onChange={(e) =>
                    this.handleChangeLinkData(e, "consultingVideo")
                  }
                  placeholder="Enter Consulting video url"
                />
              </Form.Group>
              <Form.Group controlId="aboutVideo">
                <Form.Label>About video</Form.Label>
                <Form.Control
                  type="text"
                  name="aboutVideo"
                  value={this.state.aboutVideo}
                  onChange={(e) =>
                    this.handleChangeLinkData(e, "aboutVideo")
                  }
                  placeholder="Enter About video url"
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
            <br></br>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageData: state.languages.availableLanguages,
    consultingVideo: state.auth.consultingVideo,
    aboutVideo: state.auth.aboutVideo,
    youtube: state.auth.youtube,
    facebook: state.auth.facebook,
    linkedin: state.auth.linkedin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postLanguagesToFirebase: (sendLanguagesData) => {
      dispatch(actions.putAvailableLanguages(sendLanguagesData));
    },
    SendLinkData: (urls) => {
      dispatch(actions.SendLinkData(urls));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(AdminChangeLanguagesPage, axios));
