import * as actionTypes from "../actions/actionTypes";

const initialState = {
  referenceNames: [],
};

const referenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REFERENCE_NAMES:
      return {
        ...state,
        referenceNames: action.referenceNames,
      };
    default:
      return state;
  }
};

export default referenceReducer;
