import React, { Component } from "react";
import { Button, Modal, Container } from "react-bootstrap";
import axios from "../../axios-orders";
import { connect } from "react-redux";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import firebase from "firebase";
import Form from "react-bootstrap/Form";
import * as actions from "../../store/actions/index";

class EditProfilePictureComponent extends Component {
  state = {
    showEditModal: false,
  };

  onAuthEditDiplomaButton = () => {};
  onCloseEditModal = () => {
    this.setState({
      ...this.state,
      showEditModal: false,
    });
  };

  onShowEditModal = () => {
    this.setState({
      ...this.state,
      showEditModal: true,
    });
  };

  onSucDeleteImage = () => {
    this.props.getProfilePicture();
    alert("Upload was successfull");
  };

  onDeleteImage = () => {
    const onEror = (error) => {
      console.log(error);
    };

    const folderName = "consultingAboutPageProfilePicture";
    const splitPath = folderName + "%2F";

    let imgName = this.props.image;
    imgName = imgName.split(splitPath)[1];
    imgName = imgName.split("?")[0];
    const desertRef = firebase
      .storage()
      .ref()
      .child(folderName + "/" + imgName);
    desertRef.delete().then(this.onSucDeleteImage, onEror);
  };

  postImage = (e) => {
    e.preventDefault();
    const onEror = function (error) {};
    let profilePicture = this.props.profilePicture;
    let imgNameNumberCounter = 1001;
    if (profilePicture[profilePicture.length - 1].orderNumber != null)
    {
      imgNameNumberCounter = profilePicture[profilePicture.length - 1].orderNumber + 1;
    }
    const image = document.getElementById("imageToPost").files[0];
    const storageRef = firebase
      .storage()
      .ref("consultingAboutPageProfilePicture/" + imgNameNumberCounter);
    storageRef.put(image).then(this.onDeleteImage, onEror);
  };

  render() {
    let authEditButton = null;
    if (this.props.auth) {
      authEditButton = (
        <Button variant="primary" onClick={this.onShowEditModal}>
          Edit
        </Button>
      );
    }

    let modalForProfilePicture = null;

    modalForProfilePicture = (
      <Modal show={this.state.showEditModal} onHide={this.onCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Container>
          <Form onSubmit={this.postImage}>
            <Form.Group>
              <Form.File id="imageToPost" label="Upload image!" />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              onClick={this.onCloseEditModal}
            >
              Submit
            </Button>
          </Form>
        </Container>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onCloseEditModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );

    return (
      <div>
        {authEditButton}
        {modalForProfilePicture}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    profilePicture:state.auth.profilePicture
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfilePicture: () => dispatch(actions.getProfilePicture()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(EditProfilePictureComponent, axios));
