import * as actionTypes from "./actionTypes";
import axios from "../../axios-orders";

export const setAvailableLanguages = (languages) => {
  return {
    type: actionTypes.SET_AVAILABLE_LANGUAGES,
    availableLanguages: languages,
  };
};

export const setLanguageData = (languageData) => {
  return {
    type: actionTypes.SET_LANGUAGE_DATA,
    languageData: languageData,
  };
};

export const fetchAllLanguagesData = (AllLanguagesData) => {
  return {
    type: actionTypes.FETCH_ALL_LANGUAGE_DATA,
    AllLanguagesData: AllLanguagesData,
  };
};

export const setCurrentLanguage = (language) => {
  return {
    type: actionTypes.SET_CURRENT_LANGUAGE,
    currentLanguage: language,
  };
};

export const fetchAvailableLanguagesFailed = () => {
  return {
    type: actionTypes.FETCH_AVAILABLE_LANGUAGES_FAILED,
  };
};

//////////////////////////////////////////////////
// ASYNCHRONOUS action creators
//////////////////////////////////////////////////
const BASE_URL = "https://rost-international.firebaseio.com/";

// GET available lang
export const getAvailableLanguages = () => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}languages.json`)
      .then((response) => {
        dispatch(setAvailableLanguages(response.data));

        const langString = determineDefaultLanguage(response.data);
        axios
          .get(`${BASE_URL}editableText/${langString}.json`)
          .then((response) => {
            dispatch(setLanguageData(response.data));
            dispatch(setCurrentLanguage(langString));
          })
          .catch((error) => {
            dispatch(fetchAvailableLanguagesFailed());
          });
      })
      .catch((error) => {
        dispatch(fetchAvailableLanguagesFailed());
      });
  };
};

// PUT available lang
export const putAvailableLanguages = (languages) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios
      .put(`${BASE_URL}languages.json?auth=` + token, languages)
      .then((response) => {
        // dispatch(setCurrentLanguage(response.data.aboutPage));
        this.getAvailableLanguages();
      })
      .catch((error) => {});
  };
};

// CHANGE language
// 1. GET selected lang data
// 2. set FE selected lang string
export const changeLanguage = (langString) => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}editableText/${langString}.json`)
      .then((response) => {
        dispatch(setLanguageData(response.data));
        dispatch(setCurrentLanguage(langString));
      })
      .catch((error) => {
        dispatch(fetchAvailableLanguagesFailed());
      });
  };
};

export const getAllLanguagesData = () => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}editableText.json`)
      .then((response) => {
        dispatch(fetchAllLanguagesData(response.data));
      })
      .catch((error) => {
        // dispatch(fetchAvailableLanguagesFailed());
      });
  };
};

export const postText = (key, text) => {
  return (dispatch) => {
    const langCodes = ["en", "it", "fr", "de"];
    const token = localStorage.getItem("token");
    for (let i in langCodes) {
      const patchObject = {};
      patchObject[key] = text[langCodes[i]];
      axios
        .patch(
          `${BASE_URL}editableText/${langCodes[i]}.json?auth=` + token,
          patchObject
        )
        .then((response) => {
          // dispatch(setCurrentLanguage(response.data.aboutPage));
        })
        .catch((error) => {});
    }
  };
};

//////////////////////////////////////////////////
// Helper functions
//////////////////////////////////////////////////

const determineDefaultLanguage = (languagesJson) => {
  const browserLanguageString = navigator.language.slice(0, 2);
  const languagePriorityList = [browserLanguageString, "en", "it", "de", "fr"];
  // for-of statement ensures the order of looping, unlike for-in
  for (const languageString of languagePriorityList) {
    if (languagesJson[languageString]?.available) return languageString;
  }
  return "en";
};
