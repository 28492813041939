import * as actionTypes from "../actions/actionTypes";
import * as enStrings from "../../localization/en.json";
import * as itStrings from "../../localization/it.json";
import * as deStrings from "../../localization/de.json";
import * as frStrings from "../../localization/fr.json";

const initialState = {
  availableLanguages: {},
  currentLanguage: "it",
  languageData: {},
  allLanguagesData: {},
  localStrings: itStrings.default,
  error: false,
};

const localStringsOf = (currentLanguage) => {
  switch (currentLanguage) {
    case "en":
      return enStrings.default;
    case "de":
      return deStrings.default;
    case "it":
      return itStrings.default;
    case "fr":
      return frStrings.default;
    default:
      return itStrings.default;
  }
};

const languagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AVAILABLE_LANGUAGES:
      return {
        ...state,
        availableLanguages: action.availableLanguages,
      };
    case actionTypes.FETCH_AVAILABLE_LANGUAGES_FAILED:
      return { ...state, error: true };
    case actionTypes.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.currentLanguage,
        localStrings: localStringsOf(action.currentLanguage),
      };
    case actionTypes.SET_LANGUAGE_DATA:
      return { ...state, languageData: action.languageData };
    case actionTypes.FETCH_ALL_LANGUAGE_DATA:
      return { ...state, allLanguagesData: action.AllLanguagesData };
    default:
      return state;
  }
};

export default languagesReducer;
