import * as actionTypes from "./actionTypes";
import axios from "../../axios-orders";

import firebase from "firebase";
export const setAuth = (auth) => {
  return {
    type: actionTypes.SET_AUTH,
    auth,
  };
};

export const SetAboutPageData = (language) => {
  return {
    type: actionTypes.SET_CURRENT_LANGUAGE,
    currentLanguage: language,
  };
};

export const SetLinkData = (linkData) => {
  return {
    type: actionTypes.SET_LINK_DATA,
    linkData,
  };
};

export const setDiplomasData = (urls) => {
  return {
    type: actionTypes.SET_DIPLOMAS_DATA,
    urls,
  };
};

export const setProfilePictureData = (urls) => {
  return {
    type: actionTypes.SET_PROFILE_PICTURE_DATA,
    urls,
  };
};

export const postAboutPageData = (AboutPageData) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    axios
      .patch(
        "https://rost-international.firebaseio.com/editableText/en/.json?auth=" +
          token,
        { aboutPage: AboutPageData.en }
      )
      .then((response) => {
        dispatch(SetAboutPageData(response.data));
      })
      .catch((error) => {});
  };
};

export const SendLinkData = (urls) => {
  const token = localStorage.getItem("token");
  return (dispatch) => {
    axios
      .put(
        "https://rost-international.firebaseio.com/links.json?auth=" + token,
        urls
      )
      .then((response) => {
        dispatch(SetLinkData(response.data));
        alert('Url references are successfully uploaded');
      })
      .catch((error) => {
        // dispatch(fetchAvailableLanguagesFailed());
      });
  };
};

export const initLinkData = () => {
  return (dispatch) => {
    axios
      .get("https://rost-international.firebaseio.com/links.json")
      .then((response) => {
        dispatch(SetLinkData(response.data));
      })
      .catch((error) => {});
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(setAuth(false));
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    } else {
      
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      const expirationTime = ((expirationDate.getTime() - new Date().getTime()) / 1000 );
     if (expirationDate <= new Date()) {
       dispatch(setAuth(false));
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
      } else {
       dispatch(setAuth(true));
       setTimeout(() => {
        dispatch(setAuth(false));
    }, expirationTime * 1000);
      }  
    }
  };
};


export const getDiplomas = () => {
  const folderRef = firebase.storage().ref("consultingAboutPageDiplomas");
  let numberOfImageRefs = 0;
  const urls = [];

  return (dispatch) => {
    const getDownloadUrl = (imageRef) => {
      imageRef
        .getDownloadURL()
        .then((url) => {
          urls.push(url);
          if (urls.length === numberOfImageRefs) {
            dispatch(setDiplomasData(urls));
          }
        })
        .catch((error) => {
          // Handle any errors
        });
    };
    folderRef
      .listAll()
      .then((result) => {
        numberOfImageRefs = result.items.length;
        result.items.forEach((imageRef) => {
          getDownloadUrl(imageRef);
        });
      })
      .catch((error) => {
        // Handle any errors
      });
  };
};

export const getProfilePicture = () => {
  const folderRef = firebase.storage().ref("consultingAboutPageProfilePicture");
  let numberOfImageRefs = 0;
  const urls = [];

  return (dispatch) => {
    const getDownloadUrl = (imageRef) => {
      imageRef
        .getDownloadURL()
        .then((url) => {
          urls.push(url);
          if (urls.length === numberOfImageRefs) {
            dispatch(setProfilePictureData(urls));
          }
        })
        .catch((error) => {
          // Handle any errors
        });
    };
    folderRef
      .listAll()
      .then((result) => {
        numberOfImageRefs = result.items.length;
        result.items.forEach((imageRef) => {
          getDownloadUrl(imageRef);
        });
      })
      .catch((error) => {
        // Handle any errors
      });
  };
};

export const removeDiplomaFromFirebase = (url, folderName) => {
  let onsuc = function () {
    getDiplomas();
  };
  let oneror = function (error) {};

  var imgName = url;
  var splitPath = folderName + "%2F";
  // imgName = imgName.split("pajoslav%2F")[1];
  imgName = imgName.split(splitPath)[1];
  imgName = imgName.split("?")[0];
  // var desertRef = firebase.storage().ref().child('pajoslav/' + imgName);
  var desertRef = firebase
    .storage()
    .ref()
    .child(folderName + "/" + imgName);
  desertRef.delete().then(onsuc, oneror);
};

export const removeProfilePictureFromFirebase = (url, folderName) => {
  let onsuc = function () {
    getDiplomas();
  };
  let oneror = function (error) {};

  var imgName = url;
  var splitPath = folderName + "%2F";
  // imgName = imgName.split("pajoslav%2F")[1];
  imgName = imgName.split(splitPath)[1];
  imgName = imgName.split("?")[0];
  // var desertRef = firebase.storage().ref().child('pajoslav/' + imgName);
  var desertRef = firebase
    .storage()
    .ref()
    .child(folderName + "/" + imgName);
  desertRef.delete().then(onsuc, oneror);
};
