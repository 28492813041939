import React from "react";
import GalleryFunctionality from "../../components/gallery/GalleryFunctionality.js";
import LogoStrip from "./LogoStrip.js";
import { NavLink } from "react-router-dom";

export default function StripGallery(props) {
  return (
    <GalleryFunctionality
      galleryName={props.galleryName}
      displayAndDeleteImages={(images, galleryName) => (
        <LogoStrip logoImages={images} galleryName={galleryName} />
      )}
      adminButton={
        <NavLink to={"/edit-gallery/" + props.galleryName}>
          Click here to add or delete logos
        </NavLink>
      }
    />
  );
}
