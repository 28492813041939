import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import axios from "../../axios-orders";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import firebase from "firebase";
import * as actions from "../../store/actions/index";
class Footer extends Component {
  state = {
    url: "",
  };

  componentDidMount() {
    this.props.initLinkData();
    this.props.initBannerUrlData();

    var folderRef = firebase.storage().ref("static");
    folderRef
      .listAll()
      .then((result) => {
        result.items[0]
          .getDownloadURL()
          .then((pdfUrl) => {
            if (pdfUrl) {
              this.setState({
                url: pdfUrl,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  redirectToPdf = () => {
    if (this.state.url) {
      window.open(this.state.url);
    }
  };

  render() {
    let termsAndConditions = this.state.url ? (
      <button className="footer__tnc-btn" onClick={this.redirectToPdf}>
        {this.props.localStrings.footer.termsAndConditions}
      </button>
    ) : null;
    if (this.props.auth) {
      termsAndConditions = (
        <NavLink to="/uploadTermsAndConditionsPdf">
          {this.props.localStrings.footer.termsAndConditions}
        </NavLink>
      );
    }

    const adminPanelLink = (text) => (
      <NavLink to="/admin-panel">{text}</NavLink>
    );

    const youtubeLink = this.props.auth ? (
      adminPanelLink("Youtube")
    ) : (
      <a href={this.props.youtube} target="_blank" rel="noreferrer">
        Youtube
      </a>
    );

    const facebookLink = this.props.auth ? (
      adminPanelLink("Facebook")
    ) : (
      <a href={this.props.facebook} target="_blank" rel="noreferrer">
        Facebook
      </a>
    );

    const linkedinLink = this.props.auth ? (
      adminPanelLink("LinkedIn")
    ) : (
      <a href={this.props.linkedin} target="_blank" rel="noreferrer">
        LinkedIn
      </a>
    );

    return (
      <footer className="footer">
        <Container className="footer__content">
          <div className="footer__links">
            <div className="footer__links-group footer__links-group--left">
              {termsAndConditions}
              <NavLink to="/job-application">
                {this.props.localStrings.footer.jobApply}
              </NavLink>

              <div className="footer__copyrights--mobile">
                &#169; ROST International {new Date().getFullYear().toString()}
              </div>
            </div>
            <div className="footer__links-group footer__copyrights">
              &#169; ROST International {new Date().getFullYear().toString()}
            </div>
            <div className="footer__links-group footer__links-group--right">
              <NavLink to="/contact">
                {this.props.localStrings.footer.contact}
              </NavLink>
              {youtubeLink}
              {facebookLink}
              {linkedinLink}
            </div>
          </div>
        </Container>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    localStrings: state.languages.localStrings,
    auth: state.auth.auth,
    youtube: state.auth.youtube,
    facebook: state.auth.facebook,
    linkedin: state.auth.linkedin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initLinkData: () => {
      dispatch(actions.initLinkData());
    },
    initBannerUrlData: () => {
      dispatch(actions.initBannerUrlData());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Footer, axios));
