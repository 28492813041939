import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "../../axios-orders";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions/index";
import ModalEdit from "./EditModalComponent";

class EditableText extends Component {
  state = {
    editContent: false,
    show: true,
    aboutPageDe: "",
    aboutPageIt: "",
    aboutPageEn: "",
    aboutPageFr: "",
    updateCurrentLanguageData: false,
    previewComponent: false,
    previewCurrentLanguageTextOne: "",
  };

  setShowState = (show) => {
    this.setState({ show });
  };

  handleClose = () => {
    this.setState({ show: false, editContent: false });
  };

  onAuthEditButton = () => {
    this.setState({
      show: true,
      editContent: true,
      aboutPageDe: this.props.allLanguagesData.de[this.props.textKey],
      aboutPageIt: this.props.allLanguagesData.it[this.props.textKey],
      aboutPageEn: this.props.allLanguagesData.en[this.props.textKey],
      aboutPageFr: this.props.allLanguagesData.fr[this.props.textKey],
    });
  };

  handleEditorChange = (e, type) => {
    switch (type) {
      case "en":
        this.setState({ aboutPageEn: e.target.getContent() });
        break;
      case "it":
        this.setState({ aboutPageIt: e.target.getContent() });
        break;
      case "de":
        this.setState({ aboutPageDe: e.target.getContent() });
        break;
      case "fr":
        this.setState({ aboutPageFr: e.target.getContent() });
        break;
      default:
        console.log(type);
    }
  };

  redirectToPreviewComponent = () => {
    this.setState({ editContent: false });
    switch (this.props.languageData.keyLg) {
      case "en":
        this.setState({
          previewCurrentLanguageTextOne: this.state.aboutPageEn,
        });
        break;
      case "it":
        this.setState({
          previewCurrentLanguageTextOne: this.state.aboutPageIt,
        });
        break;
      case "de":
        this.setState({
          previewCurrentLanguageTextOne: this.state.aboutPageDe,
        });
        break;
      case "fr":
        this.setState({
          previewCurrentLanguageTextOne: this.state.aboutPageFr,
        });
        break;
      default:
        console.log(this.props.languageData.keyLg);
    }

    this.setState({ previewComponent: true });
  };

  postAboutPageDataToFirebaseFromPreview = () => {
    this.setState({ show: false });

    this.setState({ editContent: false });

    const sendAboutPageData = {
      de: this.state.aboutPageDe,
      en: this.state.aboutPageEn,
      it: this.state.aboutPageIt,
      fr: this.state.aboutPageFr,
    };
    this.props.postAboutPageDataToFirebase(
      sendAboutPageData,
      this.props.textKey
    );

    setTimeout(() => {
      this.props.setLanguageData(this.props.currentLanguage);
      this.props.FetchAllLanguages();
      this.setState({
        previewComponent: false,
        aboutPageDe: this.props.allLanguagesData.de[this.props.textKey],
        aboutPageIt: this.props.allLanguagesData.it[this.props.textKey],
        aboutPageEn: this.props.allLanguagesData.en[this.props.textKey],
        aboutPageFr: this.props.allLanguagesData.fr[this.props.textKey],
      });
    }, 1000);
  };

  render() {
    let authEditButton = null;
    if (this.props.auth) {
      authEditButton = (
        <Button variant="primary" onClick={this.onAuthEditButton}>
          Edit
        </Button>
      );
    }

    let previewBodyComponent = null;
    if (this.state.previewComponent) {
      previewBodyComponent = (
        <div>
          <Button
            variant="primary"
            onClick={() => {
              this.setState({ previewComponent: false });
            }}
          >
            Close
          </Button>{" "}
          &nbsp; &nbsp;
          <Button
            variant="primary"
            onClick={this.postAboutPageDataToFirebaseFromPreview}
          >
            Save
          </Button>
        </div>
      );
    }

    let modalComponent;
    if (this.state.editContent) {
      modalComponent = (
        <ModalEdit
          handleClose={this.handleClose}
          redirectToPreviewComponent={this.redirectToPreviewComponent}
          handleEditorChange={this.handleEditorChange}
          german={this.state.aboutPageDe}
          italian={this.state.aboutPageIt}
          french={this.state.aboutPageFr}
          english={this.state.aboutPageEn}
        />
      );
    }

    let showBodyOfCompanyPage;
    if (this.props.allLanguagesData) {
      showBodyOfCompanyPage = (
        <>
          {this.state.previewComponent
            ? ReactHtmlParser(this.state.previewCurrentLanguageTextOne)
            : ReactHtmlParser(this.props.languageData[this.props.textKey])}
          {this.state.previewComponent ? previewBodyComponent : authEditButton}{" "}
        </>
      );
    }

    return (
      <>
        {modalComponent}
        {showBodyOfCompanyPage}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentLanguage: state.languages.currentLanguage,
    auth: state.auth.auth,
    allLanguagesData: state.languages.allLanguagesData,
    languageData: state.languages.languageData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postAboutPageDataToFirebase: (sendAboutPageData, textKey) => {
      dispatch(actions.postText(textKey, sendAboutPageData));
      dispatch(actions.getAllLanguagesData());
    },

    setLanguageData: (currentLanguage) =>
      dispatch(actions.changeLanguage(currentLanguage)),
    FetchAllLanguages: () => dispatch(actions.getAllLanguagesData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(EditableText, axios));
