import React, { useEffect, useState } from "react";

import imgTires from "../../../assets/images/webshop/tires.png";
import imgLaptop from "../../../assets/images/products/laptop.png";
import imgDrone from "../../../assets/images/products/drone.png";
import imgRemote from "../../../assets/images/webshop/remote.png";

export default function WebshopSVG() {
  const isFirefox = false; //navigator.userAgent.includes("Firefox");
  const remoteControlSignalSVG = () => {
    const cx = 260;
    const cy = 220;
    const baseR = 15;
    const wiFiLine = [];
    let opacity = 0.15;
    for (let i = 0; i < 6; i++) {
      const r = baseR + 20 * i;
      const circumference = Math.PI * r * 2;
      const dashOffset = circumference - circumference * 0.22;

      wiFiLine.push(
        <circle
          className={"remote-signal-" + i}
          key={i + "-" + r}
          cx={cx}
          cy={cy}
          r={r}
          fill="none"
          stroke="#1f648c"
          strokeWidth="7"
          strokeDasharray={circumference}
          strokeDashoffset={dashOffset}
          transform-origin={cx + " " + cy}
          transform="rotate(-110)"
          opacity={isFirefox ? opacity + 0.15 * i : 0}
        />
      );
    }
    return wiFiLine;
  };

  let [classes, setClasses] = useState(" ");
  useEffect(() => {
    if (!isFirefox) {
      setTimeout(() => {
        // eslint-disable-next-line
        setClasses((classes += "home-page__webshop-img--animated"));
      }, 1000);
    }
  }, []);

  return (
    <svg
      className={"home-page__webshop-img " + classes}
      viewBox="0 0 400 400"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter id="shadow-filter">
          <feDropShadow dx="3" dy="4" stdDeviation="4" floodColor="#404041" />
        </filter>
      </defs>

      <image
        x="0"
        y="50"
        href={imgTires}
        className="home-page__img-tires"
        alt="tires"
        width="230"
      />
      <image
        x="0"
        y="170"
        href={imgLaptop}
        className="home-page__img-laptop"
        alt="laptop"
        width="210"
        style={{ filter: "url(#shadow-filter)" }}
      />
      {remoteControlSignalSVG()}

      <image
        x="210"
        y="190"
        href={imgRemote}
        className="home-page__img-remote"
        alt="remote controler"
        width="100"
        transform-origin="center"
        transform="rotate(5)"
        style={{ filter: "url(#shadow-filter)" }}
      />

      <image
        x="230"
        y="10"
        href={imgDrone}
        className="home-page__img-drone"
        alt="drone"
        width="165"
      />
    </svg>
  );
}
