import React from "react";
import Tile from "./Tile.js";
import GalleryModal from "./GalleryModal.js";
import GalleryFunctionality from "./GalleryFunctionality.js";
import GalleryAddButton from "./GalleryAddButton.js";
import { Container } from "react-bootstrap";

export default function GridGallery(props) {
  return (
    <Container>
      <GalleryFunctionality
        galleryName={props.galleryName}
        displayAndDeleteImages={(images, galleryName) => (
          <GalleryModal
            galleryName={galleryName}
            images={images}
            mainDisplay={(openModal) =>
              images && (
                <div className="gallery-tiles mb-5">
                  {images.map((imageObj, i) => (
                    <Tile
                      onOpenImg={() => openModal(imageObj)}
                      data={imageObj}
                      key={imageObj.id}
                      galleryName={galleryName}
                    />
                  ))}
                </div>
              )
            }
          />
        )}
        adminButton={<GalleryAddButton galleryName={props.galleryName} />}
      />
    </Container>
  );
}
