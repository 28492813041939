import * as actionTypes from "../actions/actionTypes";

const initialState = {
  electronicsGallery: [],
  tiresGallery: [],
  RCModelsGallery: [],
  sparePartsGallery: [],
  consultingGallery: [],
};

const getOrderNumberFromImagePath = (galleryName, imageUrl) => {
  var splitPath = galleryName + "%2F"; // "%2F" is the code for "/" in urls

  const imageName = imageUrl.split(splitPath)[1]; // There might also be some additional url parameters at the end here with "?param=blah", but we don't care in this case
  const orderString = imageName.substring(0, 4);
  return parseInt(orderString);
};

const setGalleries = (state, action) => {
  const imagesArray = action.urls.map((imageUrl, i) => {
    return {
      id: i,
      name: "Image " + i,
      image: imageUrl,
      orderNumber: getOrderNumberFromImagePath(action.galleryName, imageUrl),
    };
  });

  const orderCompare = (a, b) =>
    a.orderNumber < b.orderNumber ? -1 : a.orderNumber > b.orderNumber ? 1 : 0;
  imagesArray.sort(orderCompare);

  state[action.galleryName] = imagesArray;
  return { ...state };
};

const galleriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_GALLERY_DATA:
      return setGalleries(state, action);
    default:
      return state;
  }
};

export default galleriesReducer;
