import React from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import EditableText from "../../components/EditableText";

export default function SafetyCulturePage() {
  const safetyTitle = useSelector(
    (state) => state.languages.localStrings.pages.consulting.safetyCulture
  );

  return (
    <div className="consulting_safety__wrapper  editable-text__wrapper">
      <Container>
        <h1 className="page__title pt-5 mb-5">{safetyTitle}</h1>
        <div className="commercial-float-wrap">
        </div>
        <EditableText textKey="safetyCulture" />
      </Container>
    </div>
  );
}
