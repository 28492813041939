import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import axios from "../../axios-orders";
import { Button, Container } from "react-bootstrap";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";

class Auth extends Component {
  state = {
    email: "",
    password: "",
    error: false,
  };

  checkAuthInputValue = (e) => {
    e.preventDefault();
    let url =
      "https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=" +
      process.env.REACT_APP_FIREBASE_KEY;
    const authData = {
      email: this.state.email,
      password: this.state.password,
      returnSecureToken: true,
    };
    axios
      .post(url, authData)
      .then((response) => {
        if (response === undefined) {
          this.setState({ error: true });
        }
        if (response.status === 200) {
          this.setState({ error: false });
          const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
          //const expirationDate = new Date(new Date().getTime() + 3 * 1000);
          localStorage.setItem('expirationDate', expirationDate);
          localStorage.setItem("token", response.data.idToken);
          this.props.setAuth(true);
          this.props.FetchAllLanguages();
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  bindEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  bindPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  render() {
    const formStyle = {
      marginTop: "50px",
    };

    const errorStyle = {
      color: "red",
    };

    let errorText = null;
    if (this.state.error) {
      errorText = <h1 style={errorStyle}>Invalid input!</h1>;
    }
    return (
      <Container>
        <div style={formStyle}>
          {errorText}
          <Form onSubmit={this.checkAuthInputValue}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                onChange={this.bindEmail}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                onChange={this.bindPassword}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuth: (auth) => dispatch(actions.setAuth(auth)),
    FetchAllLanguages: () => dispatch(actions.getAllLanguagesData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Auth, axios));
