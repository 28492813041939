import React, { useEffect, useState } from "react";

function LogoStrip(props) {
  const [logoOpacity, setLogoOpacity] = useState(0);
  let arrMultiplier = 3;
  let logoImages = [];
  const arrIsShort = props.logoImages.length < 8;
  if (arrIsShort) {
    arrMultiplier = 10;
  }

  for (let i = 0; i < arrMultiplier; i++) {
    logoImages = logoImages.concat(props.logoImages);
  }

  useEffect(() => {
    setTimeout(() => {
      setLogoOpacity(1);
    }, 1500);
  }, []);

  return (
    logoImages.length && (
      <div className="prod-logo__wrap mb-5" style={{ opacity: logoOpacity }}>
        <div
          className="prod-logo__slider"
          style={{
            animationDuration: logoImages.length * 2 + "s",
            animationName: arrIsShort ? "logoStripAnimShort" : "logoStripAnim",
          }}
        >
          {logoImages.map((img, i) => (
            <div className="prod-logo__slide" key={"product-logo-" + i}>
              <img
                className="prod-logo__slide-img"
                src={img.image}
                alt={"product-logo-" + i}
              />
            </div>
          ))}
        </div>
      </div>
    )
  );
}

export default LogoStrip;
