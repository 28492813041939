import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../../store/actions/index";

export default function AddReferenceButton(props) {
  const auth = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();

  return auth ? (
    <Button
      className="mt-5"
      onClick={() => {
        dispatch(actionCreators.addReference(props.referenceNames));
        dispatch(actionCreators.getReferenceNames());
      }}
    >
      Add reference
    </Button>
  ) : (
    <div></div>
  );
}
