import * as actionTypes from "./actionTypes";
import axios from "../../axios-orders";
import firebase from "firebase";

const setReferenceNames = (referenceNames) => {
  return {
    type: actionTypes.SET_REFERENCE_NAMES,
    referenceNames,
  };
};

//////////////////////////////////////////////////
// ASYNCHRONOUS action creators
//////////////////////////////////////////////////
const BASE_URL = "https://rost-international.firebaseio.com/";

// GET reference names
export const getReferenceNames = () => {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}references.json`)
      .then((response) => {
        dispatch(setReferenceNames(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const addReference = (referenceNames) => {
  const nextName = calcNextName(referenceNames);
  if (referenceNames == null) referenceNames = [];
  referenceNames.push(nextName);
  return (dispatch) => {
    const authToken = localStorage.getItem("token");
    axios
      .put(`${BASE_URL}references.json?auth=` + authToken, referenceNames)
      .then((response) => {
        dispatch(setReferenceNames(referenceNames));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const deleteReference = (referenceNames, thisReferenceName) => {
  const prefixedReferenceName = "reference_" + thisReferenceName;
  const storageFolderRef = firebase.storage().ref(prefixedReferenceName);
  return (dispatch) => {
    const authToken = localStorage.getItem("token");
    //Delete storage folder
    storageFolderRef
      .listAll()
      .then((result) => {
        result.items.forEach((imageRef) => {
          imageRef.delete();
        });
      })
      .catch((error) => {
        console.log(error);
      });

    //Delete editable text objects from baza podatka
    const langCodes = ["en", "it", "fr", "de"];
    for (let i in langCodes) {
      axios
        .delete(
          `${BASE_URL}editableText/${langCodes[i]}/${prefixedReferenceName}.json?auth=${authToken}`
        )
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
    //Izbrisati stavku iz liste referenciaxios
    const indexOfNameToDelete = referenceNames.indexOf(thisReferenceName);
    referenceNames.splice(indexOfNameToDelete, 1);
    axios
      .put(`${BASE_URL}references.json?auth=${authToken}`, referenceNames)
      .then((response) => {
        dispatch(setReferenceNames(referenceNames));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

//////////////////////////////////////////////////
// Helper functions
//////////////////////////////////////////////////

const calcNextName = (referenceNames) => {
  if (!referenceNames) return "Job1";
  const numbers = referenceNames.map((name) => parseInt(name.substring(3)));
  const next = Math.max.apply(Math, numbers) + 1;
  return "Job" + next;
};
